import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import SeoData from '../../utils/seo.json';
import { Link } from 'react-router-dom';
import { selectProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import iftarMealsImg from '../../images/landings/ramadan/iftar-meals.jpg';
import feedOrphanImg from '../../images/landings/ramadan/feed-orphan.jpg';
import waterWellImg from '../../images/home/water-well.jpg';
import { Helmet } from 'react-helmet';
import waterWellSubPhoto from '../../images/landings/ramadan/water-well-sub.png';
import volunteerImg from '../../images/home/volunteer-with-us.jpg';
import allAppealsImg from '../../images/home/allAppeals.jpg';
import instaFollowImg from '../../images/home/instaFollow.jpg';
import reportImg from '../../images/home/view-our-reports.jpg';
import orphansSubPhoto from '../../images/landings/ramadan/orphans-sub.png';
import projNoorSubPhoto from '../../images/landings/ramadan/projectnoor-sub.png';
import CarouselSection from './carouselSection';
import NewCountUp from '../../components/general/newCountUp';
import HomePageTabs from './components/tabs';
import BlessingAndMercy from './components/blessing&mercy';
import SadaqahJariyahTabs from './components/sadaqaJariyahTabs';
import AliBanatLegacy from './components/aliBanatLegacy';
import GiveWithConfidence from '../ukHome/giveWithConfidence';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import WidgetHome from '../../components/widgets/widgetHome';
import services from '../../services';
// import { appealProductsCodes } from '../MATWAppeal/details';
import AppealWidget from '../MATWAppeal/components/appealWidget';

const HomePageWithAppeal = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));
  // const subProducts = useSelector(state => getProducts(state, 'Donate Now', 'Make A Donation'));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  // const navigate = useNavigate();
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0]);

  const [topAppealsProducts, setTopAppealsProducts] = useState([0, 0, 0, 0]);
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
  const [zakatProducts, setZakatProducts] = useState([0, 0]);
  const [orphansProducts, setOrphansProducts] = useState([0, 0]);
  const [waterWellProducts, setWaterWellProducts] = useState([0, 0, 0, 0, 0]);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [appealProducts, setAppealProducts] = useState([0, 0]);
  // const [fundraiserProducts, setFundraiserProducts] = useState([0,0,0]);
  const [basicWaterWellProducts] = useState({
    title: 'BASIC WATER WELL',
    description: 'Donate to provide clean water to communities that need it is a way to make a tangible and life-changing impact.',
    img: waterWellImg,
    products: [],
    alt: 'Muslim Charity to build a water well',
  });
  const [iftarMealsProducts] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan...',
    img: iftarMealsImg,
    products: [],
  });
  const [feedOrphanProducts] = useState({
    title: 'FEED AN ORPHAN FOR 30 DAYS',
    description: 'Help provide daily nutritious staple meals for an orphaned child every day this Ramadan!',
    img: feedOrphanImg,
    products: [],
  });
  const legacy = [
    {
      image: reportImg,
      title: 'VIEW OUR REPORTS',
      subTitle: '',
      bottomText: 'LEARN MORE',
      link: '/annual-reports',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Muslim Charity to help Muslims around the world',
    },
    {
      image: "https://matwproject.org/static/media/volunteer-with-us.6070df240dea760bdc44.jpg",
      title: 'VOLUNTEER WITH US',
      subTitle: '',
      bottomText: 'SIGN UP',
      link: '/Volunteer',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'donate to muslim charity to help those in need',
    },
    {
      image: allAppealsImg,
      title: 'ALL APPEALS',
      subTitle: '',
      bottomText: 'VIEW ALL APPEALS',
      link: '/all-appeals',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Muslim Donation for our islamic appeals',
    },
    {
      image: instaFollowImg,
      title: 'FOLLOW US ON INSTAGRAM',
      subTitle: '',
      bottomText: 'STAY CONNECTED',
      link: 'https://www.instagram.com/matw_project/',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Islamic Donations to our islamic charity',
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = _prds => {
    setIsLoaded(true);
    let tempSadaqahProducts = [];
    let tempFoodAidProducts = [];
    let tempZakateProducts = [];
    let tempOrphansProducts = [];
    let tempFeedOrphansProducts = [];
    let tempTopAppealsProducts = [];
    let tempBasicWaterWellProducts = [];
    let tempWaterWellProducts = [];
    let tempIftarMealsProducts = [];
    let tempPalestineEmergencyProducts = [];
    let tempAppealProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (_prds.length) {
            if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
              // Palestine Campaign => Feed 10 Palestinian Orphans
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine'
              });
              _prds.map((_prdItem, _prdIndex) => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                }
                return _prdItem;
              });
              tempAppealProducts[0] = inner_item_product;
              tempAppealProducts[0].name = 'Palestine Emergency';
            }
            if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
              // Egyptian Settlement => Contribute Towards Providing A Family Monthly Allowance
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian',
              });
              _prds.map((_prdItem, _prdIndex) => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                }
                return _prdItem;
              });
              tempAppealProducts[1] = inner_item_product;
              tempAppealProducts[1].name = 'Palestine Refugee Resettlement';
            }
          } else {
            if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
              console.log('ok1');
              // Palestine Campaign => Feed 10 Palestinian Orphans
              inner_item_product = Object.assign({}, inner_item_product, {
                // name: 'Palestine Safe Haven: Rafah Village',
                // description: '1.7 million people in Palestine are internally displaced. They have lost everything they know, their homes, their livelihoods, their community, their families, absolutely everything.',
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine',
              });
              tempAppealProducts[0] = inner_item_product;
              tempAppealProducts[0].name = 'Palestine Emergency';
            }
            if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
              console.log('ok2');
              // Egyptian Settlement => Contribute Towards Providing A Family Monthly Allowance
              inner_item_product = Object.assign({}, inner_item_product, {
                // alternativeName: 'Palestinian Refugee EgyptSettlement',
                // name: 'Palestinian Refugee EgyptSettlement',
                // description: 'Help provide all the essentials required to set up a Palestinian refugee family as they resettle in Egypt, in their new home.',
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian',
              });
              tempAppealProducts[1] = inner_item_product;
              tempAppealProducts[1].name = 'Palestine Refugee Resettlement';
            }
          }
          // Subscription Tab Starts
          if (inner_item_product.creator === 'GD-MP015') {
            // General Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              subTitle:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              subTitleCopy:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              image_link: '/images/landings/uk/buildMasjid1.png',
              navigationLink: '/sadaqah-jariyah/build-a-masjid',
            });
            tempSadaqahProducts[0] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            // Water Well
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Water Wells',
              subTitle:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              subTitleCopy:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              image_link: waterWellSubPhoto,
              navigationLink: '/sadaqah-jariyah/build-a-water-well',
            });
            tempSadaqahProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // Food and Water Distribution
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'ORPHANS',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link: orphansSubPhoto,
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
            // Sadaqah Jariyah
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Project Noor',
              subTitle: `Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.`,
              subTitleCopy:
                'Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.',
              status: '1',
              image_link: projNoorSubPhoto,
              navigationLink: '/sadaqah-jariyah/emergency',
            });
            tempSadaqahProducts[3] = inner_item_product;
          }
          // Subscription Tab Ends

          // Start Top Appeals
          // if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') tempTopAppealsProducts[0] = inner_item_product
          // if (inner_item_product.creator === 'GD-MP015') tempTopAppealsProducts[1] = inner_item_product
          // if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') tempTopAppealsProducts[3] = inner_item_product
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestine',
            });
            tempTopAppealsProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'GD-MP015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Islamic Charity: donate to build Masjids',
            });
            tempTopAppealsProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            });
            tempTopAppealsProducts[3] = inner_item_product;
          }

          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempBasicWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempBasicWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempBasicWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempBasicWaterWellProducts[3] = inner_item_product;
          // End Top Appeals

          // Start Food Aid
          if (inner_item_product.creator === 'RM24-FA-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=kafarrah',
              staticLinkText: 'CALCULATE YOUR KAFFARAH',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-FA-011') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=fidya',
              staticLinkText: 'CALCULATE YOUR FIDYA',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
            tempFoodAidProducts[3] = inner_item_product;
          }

          // End Food Aid

          // Start Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            tempZakateProducts[1] = inner_item_product;
            tempZakateProducts[0] = Object.assign({}, inner_item_product, {
              name: 'ZAKAT CALCULATOR',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
          }
          // End Zakat

          // Start Orphan
          if (inner_item_product.creator === 'RM24-OP-014') tempOrphansProducts[1] = inner_item_product;
          // End Orphan

          // Start Water well
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') tempWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028') tempWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempWaterWellProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterWellProducts[4] = inner_item_product;
          // End Water well

          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // inner_item_product = Object.assign({}, inner_item_product, {
            //   name: 'PALESTINE APPEAL',
            // });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            tempPalestineEmergencyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {
            tempPalestineEmergencyProducts[3] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempTopAppealsProducts[2] = basicWaterWellProducts;
    tempTopAppealsProducts[2].products = tempBasicWaterWellProducts;
    tempFoodAidProducts[0] = iftarMealsProducts;
    tempFoodAidProducts[0].products = tempIftarMealsProducts;
    tempOrphansProducts[0] = feedOrphanProducts;
    tempOrphansProducts[0].products = tempFeedOrphansProducts;

    tempSadaqahProducts = tempSadaqahProducts.filter(n => n);
    tempTopAppealsProducts = tempTopAppealsProducts.filter(n => n);
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n);
    tempZakateProducts = tempZakateProducts.filter(n => n);
    tempOrphansProducts = tempOrphansProducts.filter(n => n);
    tempWaterWellProducts = tempWaterWellProducts.filter(n => n);
    tempAppealProducts = tempAppealProducts.filter(n => n);
    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n);

    setSadaqahProducts(tempSadaqahProducts);
    // setIftarProducts(tempIftarProducts);
    // setFeedOrphanProducts(tempFeedOrphansProducts);

    setTopAppealsProducts(tempTopAppealsProducts);
    setFoodAidProducts(tempFoodAidProducts);
    setZakatProducts(tempZakateProducts);
    setOrphansProducts(tempOrphansProducts);
    setWaterWellProducts(tempWaterWellProducts);
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setAppealProducts(tempAppealProducts);
  };
  // const getProductsByName = async (name) => {
  //   // setProducts(null);
  //   let tempFutureProducts = [];
  //   try {
  //     const response = await services.categoryProductsByName(name);
  //     response.data.products.map(item => {
  //       item.raised = 0;
  //       if (Number(item.status) === 1) {
  //         if (item.name === 'General Orphan Support') {
  //           // item.description = 'Give whatever you can and help MATW support these orphans in any way possible';
  //           tempFutureProducts.push(item)
  //         }
  //         if (item.name === 'Nurturing Futures') {
  //           item.goal = 200000;
  //           tempFutureProducts.push(item)
  //         }
  //         if (item.name === 'HealthGuard Orphans') {
  //           item.goal = 200000;
  //           tempFutureProducts.push(item)
  //         }
  //         if (item.name === 'Orphan Centre Lebanon') {
  //           item.goal = 500000;
  //           tempFutureProducts.push(item)
  //         }
  //       }
  //       return item;
  //     });
  //     getOrphanProductsReports(tempFutureProducts);
  //   } catch (error) {
  //     console.error('error========', error);
  //   }
  // };
  // const getOrphanProductsReports = async (tempFutureProducts) => {
  //   try {
  //     const response = await services.orphanProductTransactions();
  //     console.log("response=", response, tempFutureProducts)
  //     response.data.length && response.data.map((item, i) => {
  //       tempFutureProducts.map(future_item => {
  //         if (future_item.name === item.product_name) {
  //           future_item.raised = item.total_sales
  //         }
  //         return future_item
  //       })
  //       return null;
  //     })
  //     setFundraiserProducts(tempFutureProducts)
  //     console.log('futur products===', tempFutureProducts);
  //   } catch (error) {
  //     console.error('error========', error);
  //   }
  // };
  const getOrders = async _currency => {
    let req = {
      products: [
        'MKD-MN-001',
        'RM24-ZM-013',
        'FWD-DIS-WPV-2023-01-054',
        'EMR-DIS-PSE-2023-02',
        'EMR-DIS-MDPL-2023-01-102',
        'EMR-DIS-BFPL-2023-01-202',
        'EMR-DIS-BFPL-2023-01-203',
        'EMR-DIS-BFPL-2024-01-207',
        'EMR-DIS-AA-2024-01-207',
        'EMR-DIS-PSE-2023-01',
        'EMR-DIS-BFPL-2023-01-204',
      ],
      // limit: 20,
      currency: _currency || 'AUD',
      // start_date: new Date().toISOString().split('T')[0]
      start_date: '2024-07-27',
    };
    try {
      const response = await services.getLatestOrders(req);
      console.log('get orders=', products, response.data);
      // setAppealProducts(response.data)
      getAllProducts(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore && products && Object.keys(products).length && !isLoaded) {
      getOrders(selectedCurrencyStore);
    }
    // products && Object.keys(products).length && !isLoaded && getAllProducts();
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, products]);
  // useEffect(() => {
  //   products && Object.keys(products).length && !isLoaded && getAllProducts();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [products]);
  // useEffect(() => {
  //   getProductsByName('support an orphan');
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['home']['title']}`}</title>
          <meta name="title" content={`${seo['home']['title']}`} />
          <meta name="description" content={`${seo['home']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      {/* /* --------------------------------- Youtube -------------------------------- */}
      {/* <div className="md:container md:mx-auto mt-0 md:mt-2 mb-4- md:p-2">
        <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px] md:rounded-xl overflow-hidden">
          <iframe className='video'
            width={'100%'}
            height={'100%'}
            title={'youtube'}
            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
            src={`https://youtube.com/embed/4cI1ApoJ3qw?controls=1&autoplay=0&loop=0&mute=0&playlist=4cI1ApoJ3qw`} />
        </div>
      </div> */}
      <div className="md:container md:mx-auto mt-0 md:mt-2- md:mb-4">
        <CarouselSection />
      </div>
      {/* /* ------------------------------ Appeal Widget ----------------------------- */}
      <AppealWidget appealProducts={appealProducts} />
      {/* /* ------------------------------- Tab -------------------------------------- */}
      <HomePageTabs
        topAppealsProducts={topAppealsProducts}
        foodAidProducts={foodAidProducts}
        zakatProducts={zakatProducts}
        orphansProducts={orphansProducts}
        waterWellsProducts={waterWellProducts}
        palestineEmergencyProducts={palestineEmergencyProducts}
      />
      <NewCountUp />
      {/* /* ------------------------------- Our Promise ------------------------------ */}
      <OurPromiseSection alt={'Muslim Charity with 100% donation policy'} />
      {/* /* -------------------------------- Ramadan Message ------------------------- */}
      <BlessingAndMercy
        buttons={
          <>
            <CustomButton
              link={`../pdf/MATW_Ramadan_2024_Mid-Report_3_FINAL.pdf`}
              target="_blank"
              title="See your impact here"
              className="min-w-[200px]"
            />
            {/* <CustomButton onClick={() => navigate('/zakat/zakat-al-fitr')} title='PAY ZAKAT AL FITR' className='min-w-[200px]' /> */}
          </>
        }
      />
      {/* /* -------------------------------- Why Zakat ------------------------------- */}
      <div className="w-full flex flex-wrap justify-center items-center md:container mx-auto pt-6 px-4 md:px-0">
        {/* <div className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-[30px] sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3">
          Change lives with <span className="text-[#F60362] mr-1">YOUR Zakat</span>in these last 10 nights of Ramadan!
        </div>
        <p className="text-[#78716C] md:text-[18px] text-center mb-3 md:mb-6 lg:mb-12">
          Seek Laylatul Qadr in these final nights of Ramadan and fulfil your obligation. Calculate and give your Zakat Al Mal now.
        </p> */}
        <div className="basis-full flex gap-x-10 flex-wrap sm:flex-nowrap">
          <div className="basis-full sm:basis-1/2">
            <img src="https://cdn.matwproject.org/static/media/zakat-image.58875a1bc45f57c483d6.jpg" alt="Islamic Donation for emergency appeals" className="rounded-lg" />
          </div>
          <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
            <p className="text-[#253B7E] text-[25px] leading-[30px] my-4 font-brandingBold">
              Palestine still needs<span className="text-[#F60362] ml-1">you!</span>
            </p>
            <p className="text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] font-brandingMedium">
              The people of Palestine continue to suffer as famine looms and hunger becomes widespread. Families are being forced to live on less than
              a can of beans a day. Reports show malnourished newborns are so small that they weigh in at a little more than a kilo. Desperation for
              food is widespread, affecting the most vulnerable first, including orphaned children. Food assistance to combat famine is desperately
              needed.
            </p>
            <p className="text-[#253B7E] text-[18px] leading-[25px] my-4 font-brandingBold">
              Help us save Palestinian families and orphans with your Sadaqah today.
            </p>
            <div className="flex items-center flex-col sm:flex-row mt-6 w-full">
              <CustomButton
                onClick={() => {
                  addProduct({product: topAppealsProducts[0], currency: selectedCurrency})
                }}
                title="donate now"
                className="mb-2 sm:mb-0 min-w-[226px]"
              />
              {/* <CustomButton onClick={() => navigate('/zakat')} title='GIVE ZAKAT AL MAL' className='sm:ml-2 min-w-[226px]' /> */}
            </div>
          </div>
        </div>
      </div>
      {/* /* ------------------------------- Tabs ------------------------------------- */}
      <div className="mt-4">
        <SadaqahJariyahTabs sadaqahProducts={sadaqahProducts} currency={selectedCurrency} />
      </div>

      {/* /* -------------------------------- Ali Banat -------------------------------  */}
      <AliBanatLegacy />
      {/* /* --------------------------------- YouTube --------------------------------- */}
      <section className="w-full px-4 sm:px-20">
        <div className="md:container md:mx-auto flex flex-col flex-wrap">
          <div className="basis-full rounded-xl mt-6 bg-white p-2 lg:p-10 text-center">
            {/* <h2 className=" sm:px-10 text-center font-gotcha  mb-10 text-[30px] sm:text-[60px] text-[#f60362] ">
              Ali Banat <span className='text-[#000] '>, his Life, his Legacy, his Impact.</span>
            </h2> */}
            <p className=" sm:px-10 text-center font-gotcha  mb-10 text-[30px] sm:text-[60px] text-[#f60362] ">
              <Link to={'/ali-banat'}>
                {' '}
                Ali Banat <span className="text-[#000] ">, his Life, his Legacy, his Impact.</span>
              </Link>
            </p>
            <div className="col-span-12 h-[200px]  border-4 border-[#F60362] sm:h-[300px] md:h-[400px] lg:h-[600px]">
              <iframe
                className="video"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}
                // src="https://www.youtube.com/embed/HIbAz29L-FA?modestbranding=1&playsinline=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%2Fintercoin.org&widgetid=1"
                // credentialless
                // anonymous
                // crossOrigin="anonymous"
              />
            </div>
          </div>
          <div className="flex items-center justify-center my-5 md:my-10">
            {/* <Link to='/ali-banat' className='flex rounded-md uppercase items-center justify-center px-2 h-[35px] sm:h-[58px] w-[197px]- sm:w-[410px] bg-[#F60362] text-sm sm:text-2xl text-white font-light'>
              Learn More About Ali Banat
            </Link> */}
            <CustomButton link="/ali-banat" title="Learn More About Ali Banat" className="" />
          </div>
        </div>
      </section>
      {/* /* ----------------------------------------------------------------------- */}
      <section className="md:container md:mx-auto mt-5 sm:mt-20 px-4 sm:px-10 md:px-20">
        <div className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3">
          Get<span className="text-[#F60362] mx-1">involved</span>today!
        </div>
        <p className="md:leading-7 text-[#78716C] text-center font-['Montserrat'] text-md lg:text-lg px-4 sm:px-20 md:px-30 lg:px-32">
          Build your legacy with MATW! Create a meaningful impact in this world and the hereafter through voluntary work, compassionate donations, and
          staying connected.
        </p>
        <div className="grid grid-cols-4 gap-2 md:gap-4 xl:gap-6 md:gap-y-10 mt-8">
          {legacy.map((item, index) => (
            <div key={index} className="col-span-2 sm:col-span-2 md:col-span-1">
              <GiveWithConfidence
                minHeight={'!min-h-[160px]'}
                alt={item.alt}
                image={item.image}
                subTitle={item.subTitle}
                title={item.title}
                des={item.bottomText}
                link={item.link}
                titleClasses={item.titleClasses}
              />
            </div>
          ))}
        </div>
      </section>
      {/* -------------------------------------Impact------------------------------- */}
    </Layout>
  );
};

export default HomePageWithAppeal;
