import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const DuaForParents = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));

    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['duaForBreakingFast']['title']}`}</title>
                    <meta name="title" content={`${seo['duaForBreakingFast']['title']}`} />
                    <meta name="description" content={`${seo['duaForBreakingFast']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            {/* <h1 className='font-gotcha text-[30px] sm:text-[50px] leading-[70px] text-center text-[#253B7E]'>
                                Our Promise To You
                            </h1> */}
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    Dua for Parents: An essential dua list for our parents
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium'>
                                    The dua (prayer) holds a place of profound reverence and significance. It is a believer's direct dialogue with Allah,
                                    a moment of surrender, and a testament to the faith
                                    that Allah is the ultimate source of strength, guidance, and support. Among the many du'as that a Muslim may recite
                                    throughout their life, the dua for parents is one of the
                                    most heartfelt and significant, underpinning the deep-rooted respect and love for parents in Islamic culture.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Importance of Parents in Islam
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    Islam places immense importance on the respect and care of parents. This reverence is deeply embedded in the Quran and Hadith, where
                                    kindness to parents is often mentioned alongside the worship of Allah. The Prophet Muhammad (peace be upon him) emphasized the high
                                    status of parents, saying that paradise lies under the feet of mothers and highlighting the father's significance as the middle gate
                                    of paradise. This profound respect establishes the foundation for the dua for parents, reflecting a Muslim's
                                    desire for their parents' wellbeing in this life and hereafter.
                                </p>

                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Dua for Parents: A Reflection of Love and Respect
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    The dua for parents is more than a prayer; it is an expression of love, gratitude, and hope. It is a way for children to seek Allah's
                                    blessings for their parents, asking for their health, happiness, protection, and forgiveness. The Quranic verse "My Lord, have mercy
                                    upon them as they brought me up [when I was] small" (Quran 17:24) is often recited as a dua for parents. This verse encapsulates
                                    the essence of a child's prayer for their parents - a plea for mercy and gratitude for their upbringing.

                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Benefits of Making Dua for Parents
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    Making dua for parents has manifold benefits. It is a means of:
                                    <br />
                                    Expressing Gratitude: Recognising the sacrifices and efforts of parents in raising and nurturing their children.
                                    Seeking Forgiveness: Asking Allah to forgive parents' sins and grant them a place in paradise.
                                    <br />
                                    <br />
                                    Strengthening Bonds: Reinforcing the emotional and spiritual connection between parents and children.
                                    <br />
                                    <br />
                                    Invoking Protection: Seeking Allah's protection for parents against harm and illness.
                                    <br />
                                    <br />
                                    Earning Rewards: The act of making dua for parents is in itself rewarding, bringing blessings to the children who pray for their parents.
                                    <br /><br />
                                    How to Make Dua for Parents
                                    <br />
                                    While there are specific duas from the Quran and Hadith, dua for parents does not have a fixed formula. Muslims
                                    are encouraged to pray from the heart, in any language, at any time. The sincerity of the prayer is what matters
                                    most. A dua can be made during daily prayers, on special occasions, or at any moment when the thought of one's
                                    parents comes to mind. It is a personal and intimate act of worship, reflecting a child's
                                    wishes for their parents' wellbeing.

                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Dua for forgiveness for parents
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    رَبَّنَا اغْفِرْ لِي وَلِوَالِدَيَّ وَلِلْمُؤْمِنِينَ يَوْمَ يَقُومُ الْحِسَابُ

                                    <br /><br />
                                    Rabbana-ghfirlī wa liwālidayya wa lilmu'minīna yauma yaqūmul-hisāb
                                    <br /><br />
                                    Our Lord! Forgive me, my parents, and the believers on the Day when the judgment will come to pass.
                                    <br />
                                    (Surah Ibrahim, 14:41)
                                </p>

                            </div>
                            <div className='mt-10'>

                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    The practice of making dua for parents is a beautiful aspect of Islamic culture, embodying
                                    the faith's teachings on respect, gratitude, and love. It serves as a reminder of the
                                    pivotal role parents play in our lives and the importance of praying for their happiness
                                    and salvation. In a world that often forgets the value of its elders, the Islamic tradition
                                    of dua for parents stands as a beacon of hope, showing the timeless honor and respect
                                    that children owe to their parents.
                                </p>

                            </div>

                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-montserratBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-montserratMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/ali-banat')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-montserratBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-montserratMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/purpose')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default DuaForParents