import { Link } from 'react-router-dom';
// import logoAcnsImg from './../../images/general/logo-acns.png';
// import logoCardsImg from './../../images/general/logo-cards.png';
// import logoMatwPolicyImg from './../../images/general/logo-matw-policy.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterMenu } from '../../store/menu';
import Skeleton from 'react-loading-skeleton';
const MainFooter = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const [isUk, setIsUk] = useState(false);
  const [isUsa, setIsUsa] = useState(false);
  const [isFr, setIsFr] = useState(false);
  const menus = useSelector(state => selectFooterMenu(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const [allMenus, setAllMenus] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCheckUK = referrer => {
    const href = referrer;
    const findTerm = term => {
      if (href.includes(term)) {
        return href;
      }
    };
    switch (referrer) {
      case findTerm('matwproject.org.uk'): // UK Website
        setIsUk(true);
        break;
      case findTerm('matwprojectusa.org'): // USA Website
        setIsUsa(true);
        break;
      case findTerm('matwprojectfr.org'):
        setIsFr(true);
        break;
      default:
        setIsUk(false);
        setIsUsa(false);
    }
  };
  const getAllMenus = () => {
    setIsLoaded(true)
    setAllMenus(menus);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    menus && Object.keys(menus).length && !isLoaded && getAllMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [menus]);
  useEffect(() => {
    handleCheckUK(window.location.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="w-full bg-gradient-to-r from-[#00a3da] to-[#093484] px-2 py-6">
        <div className="md:container mx-auto grid grid-cols-12 px-2 gap-2 md:gap-4">
        {allMenus.map((item, index) => {
            return item
            ? (
              (item.status === 1 || item.status === true) && <div key={`itemmenu${index}`} className="col-span-12 sm:col-span-6 lg:col-span-3">
              <ul className="flex flex-col">
                <li className="w-full pt-2 pb-4">
                  <span className="text-white font-bold text-[20px]">{item.title}</span>
                </li>
                {item.children.map((innerItem, innerIndex) => {
                  return (innerItem.status === 1 || innerItem.status === true) && (
                    innerItem.title !== 'Social Media' && (
                      <li key={`innerMenu${innerIndex}`} className="w-full text-[#c6e9ff] transition-all duration-200 hover:pl-1 py-1">
                        {innerItem.title === 'FAQs' ? (
                          <>
                            <Link className="font-light mr-1" to={innerItem.url}>
                              {innerItem.title}
                            </Link>
                            |
                            <Link className="font-light text-sm ml-1" to={item.children[innerIndex + 1].url}>
                              {item.children[innerIndex + 1].title}
                            </Link>
                          </>
                        ) : (
                          <>
                            {isFr ? (
                              innerItem.title === 'Terms & Refunds (UK)' ? (
                                ''
                              ) : (
                                <Link className="font-light" to={innerItem.url}>{innerItem.title}</Link>
                              )
                            ) : (
                              <Link className="font-light" to={innerItem.url}>
                                {innerItem.title}
                              </Link>
                            )}
                          </>
                        )}
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            ) : (
              <Skeleton key={`itemmenuloading${index}`} height={20} />
            );
          })}
          <div
            className={`col-span-12 sm:col-span-6 lg:col-span-3 flex flex-col ${
              isUk ? 'justify-between' : 'justify-end'
            } items-center sm:items-start mt-3 sm:mt-0 px-4`}
          >
            {isUk ? (
              <ul className="flex flex-col">
                <li className="w-full pt-2 pb-4">
                  <span className="text-white font-bold text-[20px]">Bank Details</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Bank: CAF Bank</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Name: MATW PROJECT</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Number: 00035950</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Sort Code: 40-52-40</span>
                </li>
              </ul>
            ) : null}

            <div>
              {!isUk && !isUsa && !isFr && (
                <img
                  src={"https://cdn.matwproject.org/static/media/logo-acns.db0d88bdb94adb49d5b3.png"}
                  alt="MATW Project"
                  className="mb-3 w-[100px] h-[100px] sm:w-[80px] sm:h-[80px] md:w-[100px] md:h-[100px] lg:w-[60px] lg:h-[60px] xl:w-[80px] xl:h-[80px] 2xl:w-[100px] 2xl:h-[100px]"
                />
              )}
              <img
                src={"https://cdn.matwproject.org/static/media/logo-matw-policy.a2cc192c5dbcddb70279.png"}
                alt="MATW Project"
                className="mb-3 w-[260px] h-[50px] sm:w-[280px] sm:h-[52px] md:w-[335px] md:h-[63px] lg:w-[207px] lg:h-[38px] xl:w-[271px] xl:h-[51px] 2xl:w-[335px] 2xl:h-[63px]"
              />
              <img
                src={"https://cdn.matwproject.org/static/media/logo-cards.e282496a84046ba3cf8e.png"}
                alt="MATW Project"
                className="max-w-full w-[260px] h-[35px] sm:w-[280px] sm:h-[36px] md:w-[335px] md:h-[40px] lg:w-[207px] lg:h-[30px] xl:w-[271px] xl:h-[32px] 2xl:w-[335px] 2xl:h-[40px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#282828] py-6 px-4 lg:px-12 w-full">
        {isUk && (
          <p className="text-center text-[#aaa] text-lg copy-right">
            MATW Project UK is a UK registered charity (1201110)
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
              <path
                fillRule="evenodd"
                clipRule={'evenodd'}
                fill="#aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
              />
            </svg>
            2023 and registered office at 275 Featherstall Road North OLDHAM OL1 2NJ
          </p>
        )}
        {isUsa && (
          <p className="text-center text-[#aaa] text-lg copy-right">
            Head office: 501(c)(3) nonprofit organization 4860 Eisenhower Ave Unit 492 Alexandria VA 2304-7350  EIN: 92-1915834
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
            <path fillRule='evenodd' clipRule={'evenodd'} fill='#aaa' strokeLinecap="round" strokeLinejoin="round" d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"/>
          </svg>
          2023 and registered office at 275 Featherstall Road North OLDHAM OL1 2NJ */}
          </p>
        )}
        {isFr && (
          <p className="text-center text-[#aaa] text-lg copy-right">
            MATW Project FR is a FR registered charity (W751273564)
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
              <path
                fillRule="evenodd"
                clipRule={'evenodd'}
                fill="#aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
              />
            </svg>
            2023 and registered office at 43 RUE DE LEIGE 75008 PARIS, FRANCE
          </p>
        )}
        {!isUk && !isUsa && !isFr && (
          <p className="text-center text-[#aaa] text-[15px] copy-right">
            {window.location.hostname === 'matwprojectusa.org' ? (
              <>
                MATW INTERNATIONAL LTD with ABN 60610666325 is a Charity and Public Benevolence Institution (PBI) dedicated to assisting the needy in
                Australia and abroad. MATW INTERNATIONAL LTD is endorsed as a Deductible Gift Recipient (DGR). Address: 2461 Eisenhower Ave 2nd Floor,
                #035 Alexandria, VA 22314 2016-2024 MATW Project.
              </>
            ) : (
              <>
                MATW INTERNATIONAL LTD with ABN 60610666325 is a Charity and Public Benevolence Institution (PBI) dedicated to assisting the needy in
                Australia and abroad. MATW INTERNATIONAL LTD is endorsed as a Deductible Gift Recipient (DGR). Address: Level 2 47 Rickard Road,
                Bankstown, NSW 2200 Australia 2016-2024 MATW Project.
              </>
            )}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
              <path
                fillRule="evenodd"
                clipRule={'evenodd'}
                fill="#aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
              />
            </svg>
            2016-2024 MATW Project
          </p>
        )}
      </div>
    </>
  );
};

export default MainFooter;