import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Accordionn from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import ZakatWidget from '../zakat/zakatWidget';
import { Loader, OurPromiseSection } from '../../components/general';
import { waterFaqs } from './faqs';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';

import bannerPhoto from '../../images/landings/food-aid/Water-Aid-Generic-Hero-Banner-Desktop.jpg';
import bannerPhotoMobile from '../../images/landings/food-aid/Water-Aid-Generic-Hero-Banner-Mobile.jpg';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import buildWaterWells from '../../images/landings/food-aid/photo-build-water.webp';
import buildWaterSadqa from '../../images/landings/food-aid/photo-build-sadqa.webp';
import { useKeenSlider } from 'keen-slider/react';

import foodSlider1 from '../../images/landings/food-aid/food-slider1.webp';
import waterSlider2 from '../../images/landings/food-aid/water-slider2.webp';
import waterSlider3 from '../../images/landings/food-aid/water-slider3.webp';
import waterSlider4 from '../../images/landings/food-aid/water-slider4.webp';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';

const images = [foodSlider1, waterSlider2, waterSlider3, waterSlider4];

const WaterAidUATLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [mostNeededWaterProducts, setMostNeededWaterProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [wantToDoWaterProducts, setWantToDoWaterProducts] = useState([0, 0, 0]);
  const [criticalWaterProducts, setCriticalWaterProducts] = useState([0, 0, 0]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];

  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempCritical = [];
    let tempWantToDo = [];
    let tempMostNeeded = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') tempCritical[3] = inner_item_product; // Water Aid
          }
          if (inner_item.name === 'Water Aid') {
            if (inner_item_product.creator === 'FWD-DIS-WYX-2023-01-055') {
              // Water Distribution In Yemen 10K Litres
              inner_item_product = Object.assign({}, inner_item_product, {
                description: `Help provide 10,000L of water to those in need in Yemen.`,
              });
              tempMostNeeded[0] = inner_item_product;
            }
            if (inner_item_product.creator === 'FWD-DIS-WPV-2023-01-054') {
              // Water Distribution In Palestine 5K Litres
              inner_item_product = Object.assign({}, inner_item_product, {
                description: `Help provide 5,000L of water to those in need in Palestine.`,
              });
              tempMostNeeded[1] = inner_item_product;
            }
            if (inner_item_product.creator === 'FWD-DIS-WPX-2023-01-058') {
              // Water Distribution In Pakistan 10K Litres
              inner_item_product = Object.assign({}, inner_item_product, {
                description: `Help provide 10,000L of water to those in need in Pakistan.`,
              });
              tempMostNeeded[2] = inner_item_product;
            }
            if (inner_item_product.creator === 'FWD-DIS-WDL-2023-01-053') {
              // Water Distribution In Lebanon
              inner_item_product = Object.assign({}, inner_item_product, {
                description: `Help provide clean and safe drinking water for everyday use.`,
              });
              tempMostNeeded[3] = inner_item_product;
            }
            if (inner_item_product.creator === 'FWD-DIS-WYH-2023-01-057') {
              // Water Distribution In Yemen 100K Litres
              inner_item_product = Object.assign({}, inner_item_product, {
                description: `Help provide 100,000L of water to those in need in Yemen.`,
              });
              tempMostNeeded[4] = inner_item_product;
            }
            if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempMostNeeded[5] = inner_item_product; // L1 Africa Water Well

            if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') tempCritical[0] = inner_item_product; // Water For 500 People
            // if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') { // Water For 1000 People
            //     inner_item_product = Object.assign({}, inner_item_product, {
            //         name: `Water For 1000 People`,
            //         description: `Give water to 1000 people, bringing new life and energy to their existence.`,
            //         quantity: 2
            //     })
            //     tempCritical[1] = inner_item_product
            // };
            // if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') { // Water For 5000 People
            //     inner_item_product = Object.assign({}, inner_item_product, {
            //         name: `Water For 5000 People`,
            //         description: `Give water to 5000 people, bringing new life and energy to their existence.`,
            //         quantity: 10
            //     })
            //     tempCritical[2] = inner_item_product
            // };

            if (inner_item_product.creator === 'WWC-CON-H1W-2023-01-024') tempWantToDo[0] = inner_item_product; // H1 Hand Pump Water Well
            if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempWantToDo[1] = inner_item_product; // Bangladesh Basic Water Well
            if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') tempWantToDo[2] = inner_item_product; // H2 Hand Pump Water Well
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempCritical = tempCritical.filter(n => n)
    tempMostNeeded = tempMostNeeded.filter(n => n)
    tempWantToDo = tempWantToDo.filter(n => n)
    setCriticalWaterProducts(tempCritical);
    setMostNeededWaterProducts(tempMostNeeded);
    setWantToDoWaterProducts(tempWantToDo);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['foodAndWaterAid']['waterAid']['title']}`}</title>
          <meta name="title" content={`${seo['foodAndWaterAid']['waterAid']['title']}`} />
          <meta name="description" content={`${seo['foodAndWaterAid']['waterAid']['description']}`} />
        </Helmet>
      )}
      {/* Banner Section */}
      <section>
        <img src={bannerPhoto} alt="photos" className="hidden md:block w-full aspect-auto" />
        <img src={bannerPhotoMobile} alt="photos" className="block md:hidden w-full aspect-auto" />
      </section>
      {/* Donation Section */}
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold">Make a Donation</div>
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center">
            <CrisisWidget
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              fixPrice={false}
              defaultProduct={{
                creator: "MKD-GEN-MWA-2023-01-120",
                label: "Water Aid",
                value: 105
              }}
            />
          </div>
          <div className="w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center">
            <ZakatWidget
              hasSuggestPrice
              defaultProduct={{
              creator: "MKD-GEN-MWA-2023-01-120",
              label: "Water Aid",
              value: 105
          }} />
          </div>
        </section>
      </section>
      {/* Our Promise */}
      <OurPromiseSection />
      {/* Video Section */}
      <section className="lg:container mx-auto mb-8 sm:mb-16">
        <div className="relative overflow-hidden flex flex-wrap">
          <div
            className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[200px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
          >
            <div className="w-full text-[30px] px-8 text-white text-center font-montserratMedium">
              The Holy Quran:
            </div>
            <p className="text-lg md:text-2xl font-montserratMedium leading-6 mt-3 px-8 text-white text-center">
              “We made from <span className="underline">water</span> every living thing” (Surat Al-Anbya 21:30)”
            </p>
          </div>
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/QTNPDosRHi4?controls=0&autoplay=1&loop=1&mute=1&playlist=QTNPDosRHi4`} />
        </div>
      </section>
      <main className="container mx-auto">
        {/* Section Water */}
        <section className="mx-auto">
          <div className="text-[30px] lg:text-[60px] font-montserratBold text-[#253B7E] md:text-center leading-tight">
            <span className="text-[#F60362] block">Water.</span>
            It is the key to all life, and could be your key to Jannah. Use your Sadaqah to save lives by delivering clean water today.
          </div>
          <div className="space-y-6 max-w-[1080px] mx-auto mt-12">
            <p className="text-lg lg:text-2xl text-[#78716C] font-montserratMedium">
              Did you know that more than 780 million people lack access to safe and clean and drinking water? That’s more than one in every 10 people
              on the planet!
            </p>
            <p className="text-lg lg:text-2xl text-[#78716C] font-montserratMedium">
              To add, nearly 1 million people die each year from waterborne diseases, with children being the most susceptible, affecting their
              ability to receive an education. 
            </p>
            <p className="text-lg lg:text-2xl text-[#78716C] font-montserratMedium">
              For many living a life of poverty or living in conflict and natural disaster zones, safe and clean water can be the difference between
              life and death. At MATW, we’re working towards a world without poverty, where access to life’s most basic necessities - like clean water
              - is available for everyone
            </p>
            <p className="text-lg lg:text-2xl text-[#78716C] font-montserratMedium">
              We can’t do it without YOU. Use your Sadaqah and Zakat to help us deliver clean water today.
            </p>
          </div>
        </section>
        {/* /* ------------------- Section Two - Criticals Products --------------------- */}
        <section className="w-full flex flex-wrap justify-center md:container md:mx-auto py-4 md:py-8">
          {criticalWaterProducts.map((item, index) => {
            return (
              <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
              </div>
            );
          })}
        </section>
        {/* Most Needed Section */}
        <section className="py-8 md:py-12 text-center">
          <div className="text-[30px] lg:text-[60px] font-montserratBold text-[#253B7E] md:text-center leading-tight">
            Water Aid <span className="text-[#F60362]">Most Needed</span>
          </div>
          <p className="text-lg lg:text-2xl text-gray-500 font-montserratMedium mt-3">
            These countries are in urgent need of our support to address the water and food crisis.
          </p>
          {/* Countries */}
          <div className="grid grid-cols-2 md:grid-cols-5 gap-5 my-8 place-items-center max-w-[1028px] mx-auto">
            <img src={'/images/landings/flags/flag-syria.png'} alt="syria" />
            <img src={'/images/landings/flags/flag-palestine.png'} alt="palestine" />
            <img src={'/images/landings/flags/flag-africa.png'} alt="africa" />
            <img src={'/images/landings/flags/flag-bangiladesh.png'} alt="bangiladesh" />
            <div className="flex justify-center items-center col-span-2 md:col-span-1">
              <img src={'/images/landings/flags/flag-pakistan.png'} alt="pakistan"/>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-center md:container md:mx-auto py-4 md:py-8">
            {products && mostNeededWaterProducts ? (
              mostNeededWaterProducts.map((item, index) => {
                return (
                  <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })
            ) : (
              <Loader />
            )}
          </div>
        </section>
        {/* Section Sadaqah */}
        <section className="mx-auto">
          <div className="max-w-[800px] mx-auto text-[30px] lg:text-[60px] font-montserratBold text-[#253B7E] md:text-center leading-tight">
            Fullfill your
            <span className="text-[#F60362]"> Sadaqah Jariyah </span>
            With Water wells
          </div>
          <div className="space-y-6 max-w-[1080px] mx-auto mt-12">
            <p className="text-lg lg:text-2xl text-gray-500 font-montserratMedium text-center">
              Make a tangible impact by building water wells. These wells will serve as a continuous source of clean water, benefiting communities in
              need for years to come.
            </p>
          </div>
          <div className="w-full flex flex-wrap justify-center md:container md:mx-auto py-4 md:py-8">
            {products && wantToDoWaterProducts ? (
              wantToDoWaterProducts.map((item, index) => {
                return (
                  <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })
            ) : (
              <Loader />
            )}
          </div>
        </section>
        {/* Section MATW Team */}
        <section className="mx-auto">
          <div className="space-y-6 max-w-[1080px] mx-auto mt-12">
            <div className="text-[30px] lg:text-[60px] font-montserratBold text-[#253B7E] md:text-center leading-tight">
              Last year, our MATW team delivered more than <span className="text-[#F60362]"> 30,000,000L </span> of safe and clean drinking water.
            </div>
          </div>
          <div className="space-y-6 max-w-[1080px] mx-auto mt-12">
            <p className="text-lg lg:text-2xl text-gray-500 font-montserratMedium">
              From disaster zones to regions gripped in the midst of conflict, our MATW team has worked tirelessly to provide clean drinking water in
              form of filtration units, rainwater harvesting systems, water tanks, bottles and more. Clean water is a basic human right and we won’t
              stop until we reach as many people as possible.
            </p>
          </div>
          <div className="space-y-4 lg:space-y-8 pb-8 md:pb-12 lg:pb-16">
            {/* Card */}
            <div className="flex flex-col md:grid grid-cols-2 gap-8 md:gap-16 lg:gap-24 my-20 place-items-center">
              <img src={buildWaterWells} alt="Build Wells" />
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <h5 className="text-[30px] lg:text-[45px] text-[#253B7E] font-bold">Build A Water Well</h5>
                  <p className="text-sm md:text-lg text-gray-600 leading-7 font-montserratMedium">
                    When you donate to build a water wells , not only does it act as a Sadaqah Jariyah but you’re also helping impoverished
                    communities facing water scarcity, develop and grow.{' '}
                  </p>
                </div>
                <button
                  onClick={() => { window.scroll({ top:500, left: 0, behavior: 'smooth' })}}
                  className="bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px] mt-3 font-bold"
                >
                  Build A Well
                </button>
              </div>
            </div>
            {/* Card */}
            <div className="flex flex-col md:grid grid-cols-2 gap-8 md:gap-16 lg:gap-24 my-20 place-items-center">
              <img src={buildWaterSadqa} alt="Build Wells" />
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <h5 className="text-[30px] lg:text-[45px] text-[#253B7E] font-bold">Sadaqah Jariyah</h5>
                  <p className="text-sm md:text-lg text-gray-600 leading-7 font-montserratMedium">
                    Sadaqah Jariyah is a charity that continues to earn the rewards, even after the giver has passed away. When you give Sadaqah
                    Jariyah, it will support a project of lasting benefit both for the communities and for the giver. Ali Banat’s legacy started with
                    three Sadaqah Jariyah projects, and thus MATW was born.
                  </p>
                  <p className="text-sm md:text-lg text-gray-600 leading-7 font-montserratMedium mt-6">What will YOUR Sadaqah Jariyah and legacy be?</p>
                </div>
                <button
                  onClick={() => { window.scroll({ top:500, left: 0, behavior: 'smooth' })}}
                  className="bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px] mt-3 font-bold"
                >
                  Donate Now
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="py-4 md:py-10">
          <div className="md:container md:mx-auto flex justify-center flex-wrap mt-6 md:mt-12">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item} alt={`carousel_images${index}`} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 pb-6">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
        {/* Section FAQ's */}
        <section className="mx-auto">
          <div className="text-[30px] lg:text-[60px] font-brandingBold text-[#253B7E] md:text-center leading-tight">
          Water Aid Donations <span className="text-[#F60362]"> FAQ’s </span>
          </div>
          <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16 lg:gap-24">
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-montserratBold">{waterFaqs[0].question}</h5>
              <p className="text-gray-600 font-montserratMedium">
                {waterFaqs[0].answer}
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-montserratBold">{waterFaqs[1].question}</h5>
              <p className="text-gray-600 font-montserratMedium">
              {waterFaqs[1].answer}
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-montserratBold">{waterFaqs[2].question}</h5>
              <p className="text-gray-600 font-montserratMedium">
              {waterFaqs[2].answer}
              </p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6">
            {waterFaqs.map((item, index) => {
               return index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-lg" key={`faq${index}`}>
                  <Accordionn
                    key={`keyallfaq${index}`}
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-montserratBold"
                  >
                    {item.answer}
                  </Accordionn>
                </div>
              );
            })}
          </div>
        </section>
      </main>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};

export default WaterAidUATLandingPage;
