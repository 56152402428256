import React, { useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';

import Palestinepreramadan1 from '../../../../images/landings/palestine/pre-ramadan-palestine1.jpeg';

import Palestinepreramadan2 from '../../../../images/landings/palestine/pre-ramadan-palestine2.jpeg';

import Palestinepreramadan3 from '../../../../images/landings/palestine/pre-ramadan-palestine3.jpeg';

import Palestinepreramadan4 from '../../../../images/landings/palestine/pre-ramadan-palestine4.jpeg';

import Palestinepreramadan5 from '../../../../images/landings/palestine/pre-ramadan-palestine5.jpeg';

const images = [
  { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },

  { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },

  { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },

  { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },

  { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
];

const ImagesSection = () => {
  const [loaded, setLoaded] = useState(false);

  const [currentSlideImages, setCurrentSlideImages] = useState(0);

  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',

    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },

    created() {
      setLoaded(true);
    },

    slides: {
      origin: 'auto',

      perView: 1,

      spacing: 8,
    },
  });

  return (
    <section className="mt-4 md:mt-12">
      <div className="md:container md:mx-auto flex justify-center flex-wrap ">
        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
          <div ref={sliderRefImages} className="keen-slider">
            {images.map((item, index) => {
              return (
                <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                  {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}

                  <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full  flex justify-center">
          {loaded && instanceRefImages.current && (
            <div className="dots flex pt-3 ">
              {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRefImages.current?.moveToIdx(idx);
                    }}
                    className={
                      'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                      (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                    }
                  ></button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImagesSection;
