import React from 'react'

const DonateSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className={`md:container md:mx-auto px-4 py-8 md:py-12 ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`} dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center font-bold`}>
        Donate to <span className="text-[#f60362]">Palestine</span>
      </h1>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4`}>
        <p>
          Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children.
          Over 63,000 Palestinians have been injured, and more than 1.9 million people have become internally displaced. Families have fled south,
          where conditions are barely liveable. Urgent food, water, and medical supplies are required to ensure their survival.
        </p>
        <p>
          MATW is on the ground providing life-saving assistance for displaced families in Palestine and, more recently, for Palestinian families
          seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive and to help refugee families start over.
        </p>
        <p>
          You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
          family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
          Zakat-applicable.
        </p>
      </div>
    </div>
  )
}

export default DonateSection