import React from 'react'
import { useKeenSlider } from 'keen-slider/react'

const VideosSection = ({ videos, youtube, videoBoxClass }) => {

    const [sliderRef, instanceRef] = useKeenSlider(
        {
            slides: {
                perView: 1,
                spacing: 10,

            },
            
            spacing: 16,
            loop: false,

            controls: true,
            slideChanged() {
                // console.log('slide changed')
            },
        },
    )

    return (
        <>
            {!youtube ?

                <>
                    <div className='md:container mx-auto hidden pt-4 sm:flex flex-wrap sm:flex-nowrap justify-center gap-6 sm:gap-4 px-4 sm:px-0 md:gap-6 ' >
                        {videos?.map((item, index) => (
                            <div className='basis-full bg-white sm:basis-1/3 px-4 py-4  shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] rounded-[10px]'>
                                <div className="col-span-12 ">
                                    <video className='video rounded-xl h-[500px] w-full' controls
                                        src={item.link}
                                    />
                                </div>
                            </div>
                        ))}

                    </div>

                    {/* mobile silder */}
                    <div className='block sm:hidden px-4 relative '>
                        <div ref={sliderRef} className="keen-slider px-1 py-2">
                            {videos?.map((item, index) => (

                                <div className="keen-slider__slide  p-4 h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] rounded-[10px]">
                                    <video className='video rounded-xl h-[500px] w-full' controls
                                        src={item.link}
                                    />


                                </div>
                            ))}


                        </div>


                        <svg width={20} height={20}
                            onClick={() => {
                                instanceRef.current.prev();
                            }}
                            className={`arrow text-xl absolute left-0 top-36`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                        </svg>
                        <svg width={20} height={20}
                            onClick={() => {
                                instanceRef.current.next();
                            }}
                            className={`arrow text-xl absolute right-0 top-36`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                        </svg>
                    </div>
                </>

                :


                // Youtube  Vidoes slider for mobile view only


                <div className="my-4 md:container md:mx-auto ">
                    {/* <h1 className='text-[#14A2DC] text-3xl sm:text-[60px] sm:mb-16 text-center'>Latest Updates</h1> */}

                    <div className='hidden sm:flex flex-wrap sm:flex-nowrap justify-center gap-6 sm:gap-4 px-4 sm:px-0 md:gap-6 md:px-4' >
                        {videos?.map((item, index) => (

                            <div key={index} className={`basis-full ${videoBoxClass ? videoBoxClass : 'border px-4 pt-4 bg-white border-[#b8b7b7] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)]'}     sm:basis-1/3  min-h-[auto]  rounded-[10px]`}>
                                <div className="col-span-12 h-[200px] sm:min-h-[350px] md:min-h-[400px] lg:min-h-[600px]">
                                    <iframe className='video rounded-xl'
                                        width={'100%'}
                                        height={'100%'}
                                        title={'youtube'}
                                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                        src={item.link}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <h1 className=' mb-2 text-left text-[#78716C] text-[16px]'>
                                        {item?.date}
                                    </h1>
                                    <p className='text-black font-bold  sm:mb-0 text-lg text-left'>
                                        {item?.title}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/*Youtube  Vidoes slider for mobile view only */}
                    <div className='block sm:hidden px-4 relative'>
                        <div ref={sliderRef} className="keen-slider px-1 py-2">

                            {videos.map((item, index) => (

                                <div key={index} className={`keen-slider__slide  p-4 ${item.title ?'h-[320px]': 'h-[300px]'} shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] rounded-[10px]`}>
                                    <iframe className='video rounded-xl h-[220px]'
                                        width={'100%'}
                                        height={'100%'}
                                        title={'youtube'}
                                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                        src={item.link}
                                    />
                                    <div className='mt-4'>
                                        <h1 className=' mb-2 text-left text-[#78716C] text-[16px]'>
                                            {item?.date}
                                        </h1>
                                        <p className='text-black font-bold  sm:mb-0 text-lg text-left'>
                                            {item?.title}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <svg width={20} height={20}
                            onClick={() => {
                                // console.log(instanceRef)
                                instanceRef.current.prev();
                            }}
                            className={`arrow text-xl absolute left-0 top-36`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                        </svg>
                        <svg width={20} height={20}
                            onClick={() => {
                                // console.log(instanceRef)
                                instanceRef.current.next();
                            }}
                            className={`arrow text-xl absolute right-0 top-36`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                        </svg>
                    </div>
                </div>
            }
        </>
    )
}

export default VideosSection;