import React from 'react';
import Accordion from '../../../../components/general/accordion';

const FAQSectionOtherPalestinePage = ({ FAQs, faqTitle = 'Palestine Donations', lang = 'en' }) => {
  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 pt-10" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className={`text-[#253B7E] text-left sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-bold`}>
        {faqTitle} <span className="text-[#F60362]"> FAQ’s</span>
      </h2>
      <div className={`grid grid-cols-${FAQs.length <= 3 ? FAQs.length : 3} gap-x-16 gap-y-8`}>
        {FAQs.map(
          (item, index) =>
            index < 3 && (
              <div className="col-span-3 sm:col-span-1">
                <p className={`text-[#253B7E] text-lg md:text-2xl font-bold`}>{item.question}</p>
                <p className={`text-base mt-3 text-[#78716C]`}>{item.answer}</p>
              </div>
            ),
        )}
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
        {FAQs.map((item, index) => {
          return (
            index > 2 && (
              <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                <Accordion
                  key={`keyallfaq${index}`}
                  shadow
                  title={item.question}
                  index={index}
                  lang={lang}
                  level={1}
                  noBorder={true}
                  className="!text-[#253B7E] text-lg !font-bold"
                >
                  {item.answer}
                </Accordion>
              </div>
            )
          );
        })}
      </div>
    </section>
  );
};

export default FAQSectionOtherPalestinePage;
