import { useEffect, useState } from 'react';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
// import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineCampaignFaqs } from './components/faqs';
import Accordion from '../../components/general/accordion';
import CrisisWidget from './components/crisisWidget';
import donationPolicyImg from '../../images/landings/winter/donation-policy.png';
import eycImg from '../../images/landings/palestine/eyc.png';
import unrwaImg from '../../images/landings/palestine/unrwa.png';
import shImg from '../../images/landings/palestine/sh.png';
import VideosSection from '../../components/general/videosSection';
import { impactInfo, videos } from './fixtures';

let faqs = palestineCampaignFaqs.filter(i => i.id !== 2);
const EmergencyResponseLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [products, setProducts] = useState([0, 0, 0, 0]);
    // const [allProducts, setAllProducts] = useState();
    // const [isLoading, setIsLoading] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = async (name) => {
        let tempCritical = [];
        try {
            const response = await services.categoryProductsByName(name);
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    if (item.name.trim() === 'Palestine Appeal') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Emergency Medical Kit') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Essential Family Food Pack') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Medical Relief') tempCritical.push(item);
                }
                return item;
            });
            setProducts(tempCritical);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const customUseScript1 = (url, content) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.innerHTML = content;
        document.getElementsByTagName('head')[0].appendChild(script);
      };
      const customUseScript4 = url => {
        const script = document.createElement('script');
        script.src = url;
        document.getElementsByTagName('body')[0].appendChild(script);
      };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        getProductsByName('palestine');
        //customUseScript1('https://cdn.gtranslate.net/widgets/latest/float.js', '');
        customUseScript4('https://maps.googleapis.com/maps/api/js?key=AIzaSyCXVJ062M2GPfxr4DvmF55qSbSp4qh0S6U&libraries=places');
        // window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
                    <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
                    <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
                </Helmet>
            }
            {/* <div className="w-full flex flex-wrap bg-[url('../public/images/landings/crisis/palestine/palestine-banner-mobile.jpg')] md:bg-[url('../public/images/landings/crisis/palestine/palestine-banner.jpg')] bg-[#F60362]- bg-center sm:bg-center bg-cover md:py-8 max-[500px]:min-h-[220px] min-[501px]:min-h-[260px] sm:min-h-[380px] md:min-h-[125px] lg:min-h-[160px] xl:min-h-[200px] 2xl:min-h-[240px] min-[1640px]:min-h-[270px] px-4 md:px-0" /> */}
            {/* <div className='hidden sm:flex max-w-[1440px] md:mx-auto'><img src={Banner} alt="palestine-banner" /></div>
            <div className='sm:hidden max-w-[1440px] md:mx-auto'><img src={BannerMobile} alt="palestine-banner" /></div> */}
            <section className="h-[400px] bg-center bg-cover bg-no-repeat bg-[url('../src/images/landings/palestine/mobile-palestine-banner.jpg')] sm:bg-[url('../src/images/landings/palestine/palestine-hero-banner.jpg')]" />

            {/* /* ------------------------------- Widget ----------------------------------- */}
            {/* <div className='w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center'>
                <CrisisWidget
                    defaultProduct={{
                        creator: "EMR-DIS-PSE-2023-01",
                        label: "Palestine Appeal",
                        value: 255
                    }}
                />
            </div> */}
            <section className='max-w-[1440px] md:mx-auto bg-[#f60362] sm:px-0 px-4 '>
                <div className='text-[#fff]   pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                <div className='w-full   lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                    <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                        defaultProduct={{
                            creator: "EMR-DIS-PSE-2023-01",
                            label: "Palestine Appeal",
                            value: 255
                        }}
                    />
                </div>

            </section>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Donation Policy ------------------------ */}
            <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                <div className="basis-full flex justify-center items-center px-4 md:basis-1/6">
                    <img src={donationPolicyImg} alt="donation policy" />
                </div>
                <div className="basis-full flex px-4 md:basis-5/6">
                    <p className="text-[#14A2DC] text-base md:text-lg">
                        <b>Our promise to you </b><br />
                        Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a global
                        humanitarian relief organisation working across 24 countries around the world. Our 100%
                        donation policy ensures that every single donation goes the extra mile in reaching those most in need.

                    </p>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Products ------------------------------- */}
            <div className="md:container md:mx-auto flex flex-wrap mt-5">
                <div className="basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[125px]">
                    Palestine Emergency
                </div>
                {/* <div className="basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[125px]">
                    {!isLoading ? allProducts.name : <Skeleton height={80} />}
                </div> */}
                <p className='w-full text-center mb-4 md:mb-8 text-lg md:text-xl px-4'>
                    <span className='text-[#f60362]'>Donate</span> today and <span className='text-[#f60362]'>join us</span> in making a difference to those severely affected in the current conflict.

                    <br /> <br />
                    Our MATW team is on the ground working day and night to send life-saving supplies to orphaned
                    children and families in need.
                    <br />
                    We are providing essential food, water, shelter, hygiene kits and all urgent support. Our recent
                    partnership with UNWRA and the Egyptian Youth Council ensures your donations are reaching
                    those most in need in Palestine.
                </p>
                <div className="basis-full">
                    <div className="flex flex-row justify-center flex-wrap">
                        {products.map((item, index) => {
                            return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <ProductBox product={item} currency={selectedCurrency} />
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* --------------------------------- The People ----------------------------- */}
            <div className="w-full mt-5 bg-[#00a3da]">
                <div className="md:container md:mx-auto mt-5 flex flex-wrap text-white pb-8 md:pb-16">
                    <div className='basis-full mt-6 p-6 lg:p-12 text-center'>
                        <p className="text-center text-xl md:text-5xl">
                            The people of
                            <span className="font-['Gotcha'] font-medium text-[#f60362] md:text-5xl mx-2 md:mx-4">
                                Palestine
                            </span>
                            need your help
                        </p>
                        <p className="text-center text-base md:text-2xl px-4 mt-2">
                            It’s been more than three months of ongoing conflict. The lives of children and their
                            families continue to be uprooted, leaving many orphaned.

                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            The current situation in Palestine is catastrophic. More than 22,000 have lost their
                            lives and more than 57,000 have been injured. Many children are being left orphaned,
                            with no one to care for them and protect them.
                            <br />
                            <br />

                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            More than 1.9 million people have become internally displaced, around half of them
                            are children. Families have fled South, leaving everything behind, seeking refuge
                            in shelters that can no longer accommodate the numbers. Now, during winter, everyone
                            is exposed to harsher conditions that are especially dangerous for children. Not
                            only are families trying to protect themselves from violence, they now have to
                            worry about how to protect themselves from the biting winter cold.
                            <br />
                            <br />
                        </p>
                    </div>
                    <div className='basis-full flex justify-center py-4 md:py-8'>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div>

            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Impacts -------------------------------- */}
            <div className="md:container md:mx-auto flex flex-wrap ">
                <div className='basis-full mt-6 lg:min-h-[250px] lg:p-12'>
                    <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                        MATW’s IMPACT
                    </h1>
                    <div className=" flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
                        <h2 className="text-3xl md:text-[45px] w-full text-[#F60362] text-center font-black ">
                            <span className="font-normal text-2xl md:text-4xl text-[#282828] mr-1">
                                Total Impact:
                            </span>
                            &nbsp; {impactInfo.total}
                        </h2>
                        <h4 className='text-[#282828] text-center my-4'>Last Update: {impactInfo.lastUpdate}</h4>
                    </div>
                </div>
                <div className='flex flex-wrap justify-center px-2 md:px-4'>
                    <div className='basis-full md:basis-1/2 lg:min-h-[388px] px-2 md:px-4 mb-4 md:mb-8'>
                        <div className='flex flex-wrap sm:flex-nowrap sm:justify-end lg:justify-start rounded-3xl overflow-hidden'>
                            <div className='basis-full sm:basis-1/2 h-[300px] sm:min-h-[250px] lg:h-[388px] bg-cover bg-no-repeat bg-[url("../public/images/landings/crisis/palestine/medicalkit-mobile.png")] sm:bg-[url("../public/images/landings/crisis/palestine/medicalkit.png")]' />
                            <div className='px-2 pt-4 sm:pt-0 sm:px-4 basis-full sm:basis-1/2 bg-[#F60362]  flex flex-col justify-center sm:items-start items-center'>
                                <div className='text-2xl sm:text-3xl md:text-4xl text-white font-black text-center sm:text-left mb-2 md:mb-4'>{impactInfo.medicalKits}</div>
                                <div className='text-white font-bold lg:text-[30px] '>Medical Kits</div>
                                <p className='sm:w-full sm:text-[14px] lg:text-[22px] text-white text-center sm:text-left px-2 pb-10 sm:px-0'>Medical Kits were distributed to victims.</p>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full md:basis-1/2 lg:min-h-[388px] px-2 md:px-4 mb-4 md:mb-8'>
                        <div className='flex flex-wrap sm:flex-nowrap sm:justify-end lg:justify-start rounded-3xl overflow-hidden'>
                            <div className='basis-full sm:basis-1/2 h-[300px] sm:min-h-[250px] lg:h-[388px] bg-cover bg-no-repeat bg-[url("../public/images/landings/crisis/palestine/fuel-mobile.png")] sm:bg-[url("../public/images/landings/crisis/palestine/fuel.png")]' />
                            <div className='px-2 pt-4 sm:pt-0 sm:px-4 basis-full sm:basis-1/2 bg-[#253B7E]  flex flex-col justify-center sm:items-start items-center'>
                                <div className='text-2xl sm:text-3xl md:text-4xl text-white font-black text-center sm:text-left mb-2 md:mb-4'>{impactInfo.fuel}</div>
                                <div className='text-white font-bold lg:text-[30px] '>Fuel</div>
                                <p className='sm:w-full sm:text-[14px] lg:text-[22px] text-white text-center sm:text-left px-2 pb-10 sm:px-0'>Fuel for Al-Shifa hospital to operate medical equipment and lighting.</p>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/mattress.png")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.mattresses}</div>
                                <div className='text-[#F60362] my-2 text-xl font-bold text-center'>Mattresses</div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/foodpkg.png")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.foodPacks}</div>
                                <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Food Packs</div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/blanket.png")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.blankets}</div>
                                <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Blankets</div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/meal.png")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.hotMeals}</div>
                                <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Hot Meals</div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/medical-aid.jpg")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.medicalAid}</div>
                                <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Medical Assistance</div>
                            </div>
                        </div>
                    </div>
                    <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                        <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                            <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/hygieneKits.jpeg")]' />
                            <div className='mt-6'>
                                <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.hygieneKits}</div>
                                <div className='text-[#F60362] my-2  text-xl font-bold text-center'>HYGIENE KITS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Latest Update -------------------------- */}
            <section className="md:container md:mx-auto px-4 md:my-12">
                <VideosSection youtube videos={videos} videoBoxClass={'p-0'} />
            </section>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Muslim Arounds ------------------------- */}
            <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 ">
                <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                    Muslims Around the World (MATW) on the ground in Palestine:
                </h1>
                <h4 className='text-[#282828] text-center my-6 basis-full'>In Partnership with</h4>
                <div className="basis-full md:basis-1/2 lg:basis-1/2 flex flex-wrap">
                    <div className="basis-1/3 flex justify-center items-center">
                        <img src={shImg} alt="sh logo" className='max-h-[100px]' />
                    </div>
                    <div className="basis-1/3 flex justify-center items-center">
                        <img src={unrwaImg} alt="unrwa logo" className='max-h-[120px]' />
                    </div>
                    <div className="basis-1/3 flex justify-center items-center">
                        <img src={eycImg} alt="eyc logo" className='max-h-[140px]' />
                    </div>
                </div>
                <div className="text-[#253B7E] text-lg md:text-xl mt-8 md:mt-12">
                    MATW has been working on the ground in Palestine delivering urgent support during emergencies,
                    providing care for orphans through our care program and more recently, rebuilding homes through
                    our project build initiative.

                    <br />
                    <br />
                    Our ground team is delivering emergency medical kits, urgent food packs, fuel, hot meals and
                    more to displaced families. Our primary focus
                    is reaching those most vulnerable and in need.
                    <br />
                    <br />
                    Our long standing presence in Palestine further reinforces our efforts, enabling us to have
                    supplies on standby for an immediate response. Our MATW team has been quick to deliver urgent
                    essentials to those directly affected by the conflict.

                    <br />
                    <br />
                    With a focus on the most vulnerable including orphans, children and their families, widows
                    and the elderly, our ground team is delivering emergency medical kits, urgent food packs,
                    fuel, hot meals and more to displaced families.
                    <br />
                    <br />
                    Prior to its shutdown, MATW’s partnership with Al-Shifa Hospital meant that your donations
                    were a critical lifeline for the people of Palestine. Through this partnership, we delivered life-saving essentials such as stretchers, and beds, mattresses and blankets, medical kits and supplies, assistance with staff support, fuel and so much more to ensure the continued operation of the hospital.

                    <br />
                    <br />
                    Our team has now partnered with UNRWA, the Egyptian Youth Council, Egyptian Red Crescent and
                    The Jordanian Hashemite Charity Organisation (JHCO) to ensure aid is reaching those in need
                    in Palestine, and are hard at work on the ground putting your donations to action.
                    <br />
                    <br />
                    We are determined as ever to never give up. Every life is valuable and your support allows
                    us to continue doing the work that we do.

                    <br />
                    <br />
                    With full transparency to our donors, every single donation to our Palestine Emergency
                    Appeal will go directly to the field, with a 100% donation policy.

                    <br />
                    <br />
                    <span className='text-[#f60362]'>Donations to our Palestine Emergency Appeal are also
                        100% Zakat applicable.</span>
                    <div className='basis-full flex justify-center py-3 md:py-3'>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-full' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------------- Youtube -------------------------------- */}
            <div className="md:container md:mx-auto mt-5 flex flex-wrap">
                <div className="basis-full rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                    <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title={'youtube'}
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src="https://youtube.com/embed/9kvOCK3HKMc?autoplay=0"
                        />
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Palestine donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {faqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}

export default EmergencyResponseLandingPage;
