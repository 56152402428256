import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import ShowCurrencies from '../../../utils/showCurrencies';
import SelectCurrency from '../../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import ConvertUSD from '../../../utils/convertUSD';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { selectProducts } from '../../../store/products';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const CrisisWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    className,
    defaultProduct,
    mode,
    donateBtnText,
    arabic,
    fixPrice,
    floating,
    ramadanMobileWidget,
    lang = 'en',
    showSuggestedPrices = true,
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  const products = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    let crisisWidgetProducts = [];

    if (arabic || lang === 'ar') {
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item_product.creator === defaultProduct.creator) {
              setSelectedProduct(inner_item_product);
            }

            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Morocco Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'فلسطين أكثر ما يحتاج', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
              // Afghanistan Earthquake Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطبية في فلسطين', creator: inner_item_product.creator });
            }

            if (inner_item_product.creator === 'MKD-GD-MP001') {
              // Libya Floods
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'حيث يحتاج الأكثر', creator: inner_item_product.creator });
            }

            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'نداء المغرب', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'فيضانات ليبيا', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطارئة', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة غذائية', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة المياه', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الأيتام حول العالم', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الصدقة الجارية', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'التعليم', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مشروع النور', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'امشروع البناء', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'توليد الدخل', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في تركيا', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في سوريا', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'RM24-ZM-013') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'زكاة المال', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'INT-001') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'تنقية الربا', creator: inner_item_product.creator });
            }

            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });

      tempProductsList = tempProductsList.filter(function ({ creator }) {
        return !this.has(creator) && this.add(creator);
      }, new Set());
    } else if (lang === 'fr') {
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item_product.creator === defaultProduct.creator) {
              setSelectedProduct(inner_item_product);
            }

            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Morocco Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
              // Afghanistan Earthquake Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }

            if (inner_item_product.creator === 'MKD-GD-MP001') {
              // Libya Floods
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }

            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: `AIDE ALIMENTAIRE`, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: `Les orphelins dans le monde`, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'RM24-ZM-013') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'INT-001') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }

            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });

      tempProductsList = tempProductsList.filter(function ({ creator }) {
        return !this.has(creator) && this.add(creator);
      }, new Set());
    } else {
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item.name === 'Build A Water Well') {
              if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
                crisisWidgetProducts.push(inner_item_product); // Water well
                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
              }
            }
            if (inner_item.name === 'Orphans') {
              if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
                crisisWidgetProducts.push(inner_item_product); // Support an Orphan Centre
                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
              }
            }
            if (inner_item.name === 'Caravan') {
              if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
                crisisWidgetProducts.push(inner_item_product); // Emergency Caravan Long Term Shelter
                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
              }
            }
            if (inner_item.name === 'Palestine') {
              if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                crisisWidgetProducts.push(inner_item_product); // Palestine Appeal
                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
              }
              if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({ value: inner_item_product.id, label: 'Medical Relief', creator: inner_item_product.creator });
              }
            }
            if (inner_item.name === 'Make A Donation') {
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            if (inner_item.name === 'Winter Campaign') {
              if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
                crisisWidgetProducts.push(inner_item_product); // Keep Orphans Warm Where Most Needed
                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
              }
            }
            if (defaultProduct) {
              if (inner_item_product.creator === defaultProduct.creator) {
                setSelectedProduct(inner_item_product);
              }
            } else {
              if (inner_item.name === 'Make A Donation') {
                setSelectedProduct(inner_item.products[0]);
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
    }

    setProductsList(tempProductsList);
    setAllProducts(crisisWidgetProducts);
  };
  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };
  const handleSelectedProduct = product => {
    allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
  };
  const handleSelectedItem = (currency, index) => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    setSelectedPrice(tempSelectedPrice);
  };
  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const suggestedPrices = fixPrice
    ? [
        {
          aud: 50,
          usd: 50,
          cad: 50,
          sgd: 50,
          gbp: 50,
          eur: 50,
          myr: 50,
          aed: 50,
          idr: 50,
        },
        {
          aud: 150,
          usd: 150,
          cad: 150,
          sgd: 150,
          gbp: 150,
          eur: 150,
          myr: 150,
          aed: 150,
          idr: 150,
        },
        {
          aud: 300,
          usd: 300,
          cad: 300,
          sgd: 300,
          gbp: 300,
          eur: 300,
          myr: 300,
          aed: 300,
          idr: 300,
        },
      ]
    : [
        {
          aud: 100,
          usd: 70,
          cad: 90,
          sgd: 90,
          gbp: 60,
          eur: 60,
          myr: 300,
          aed: 240,
          idr: 1002020,
        },
        {
          aud: 250,
          usd: 160,
          cad: 220,
          sgd: 220,
          gbp: 130,
          eur: 150,
          myr: 760,
          aed: 580,
          idr: 2505050,
        },
        {
          aud: 500,
          usd: 320,
          cad: 430,
          sgd: 440,
          gbp: 260,
          eur: 300,
          myr: 1510,
          aed: 1160,
          idr: 5010090,
        },
      ];
  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
    index: 1,
  });
  const suggestedRecurringModes = [
    { value: 'one-off', label: lang === 'fr' ? `Don unique` : lang === 'ar' ? `فردي` : 'Single' },
    { value: 'week', label: lang === 'fr' ? `Don hebdomadaire` : lang === 'ar' ? `أسبوعي` : 'Weekly' },
    { value: 'month', label: lang === 'fr' ? `Don mensuel` : lang === 'ar' ? `شهري` : 'Monthly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    console.log('before send', tempProduct);
    addProduct({ product: tempProduct, currency: currentCurrency });
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      selectedPrice ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
    } else {
      setCurrentCurrency('AUD');
      handleSelectedItem('AUD', selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && !allProducts.length && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return floating ? (
    <div
      dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${
        arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''
      } w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}
    >
      <div className="w-full grid md:grid-cols-2 xl:grid-flow-col auto-cols-max gap-2 ">
        <div className="flex gap-2">
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full min-w-[260px]"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>

        <div className="flex gap-2">
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
          {suggestedPrices.map((item, index) => {
            return (
              <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                <div
                  className={`h-12 transition-all duration-200 cursor-pointer flex justify-center items-center min-w-[64px] rounded-md py-2 md:py-4 ${
                    lang === 'ar' ? 'px-1 text-xs md:text-sm' : 'px-1 md:px-2 text-lg- md:text-lg'
                  } ${
                    selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                      ? 'bg-[#F60362] text-white'
                      : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                  }`}
                  onClick={() => {
                    handleSelectedItem(currentCurrency, index);
                    setProductAmount('');
                  }}
                >
                  {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], false)}
                </div>
              </div>
            );
          })}
        </div>
        <input
          value={productAmount}
          className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
          type="number"
          onChange={e => {
            setProductAmount(e.target.value);
            setSelectedPrice(null);
          }}
          placeholder={arabic || lang === 'ar' ? 'كمية اخرى' : lang === 'fr' ? 'Autre montant' : 'Other amount'}
        />
        <button
          disabled={!selectedProduct.id}
          className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
            isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
          }`}
          onClick={handleAddNewProduct}
        >
          {donateBtnText ? donateBtnText : lang === 'fr' ? 'Don rapide' : arabic || lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
        </button>
      </div>
    </div>
  ) : ramadanMobileWidget ? (
    <div
      dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${
        arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''
      } w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}
    >
      <div className="w-full flex justify-center gap-2- flex-wrap min-[625px]:flex-nowrap">
        <div className="basis-1/2 p-1">
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>
        <div className="basis-1/2 p-1">
          <div className="flex bg-white rounded-md">
            <SelectCurrency
              onChangeCurrency={e => setCurrentCurrency(e.value)}
              classNamePrefix="orphan_select"
              hideFlag
              className={`${arabic || lang === 'ar' ? '[&_span]:pr-1' : ''} !w-[85px]- !text-sm`}
            />
            <input
              value={productAmount}
              className="p-1 md:p-2 rounded-lg text-sm md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent w-[70px]"
              type="number"
              onChange={e => {
                setProductAmount(e.target.value);
                setSelectedPrice(null);
              }}
              placeholder={arabic || lang === 'ar' ? 'المبلغ' : lang === 'fr' ? 'Montante' : 'Amount'}
            />
          </div>
        </div>
        <div className="flex basis-1/2 gap-2- p-1">
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-[100px]- w-full text-sm md:text-base"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>
        <div className="basis-1/2 p-1">
          <button
            disabled={!selectedProduct.id}
            className={`hidden text-white text-sm md:text-lg transition-all duration-200 sm:flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            {donateBtnText ? donateBtnText : lang === 'fr' ? 'Faire un don rapide' : arabic || lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
          </button>
          <button
            disabled={!selectedProduct.id}
            className={`w-full text-white text-sm md:text-lg transition-all duration-200 flex sm:hidden items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            {donateBtnText ? donateBtnText : arabic || lang === 'ar' ? 'يتبرع' : lang === 'fr' ? 'FAIRE UN DON' : 'DONATE'}
          </button>
        </div>
        {/* <div className="w-full flex px-1 gap-2">
        </div> */}
      </div>
    </div>
  ) : (
    <div
      dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${
        arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''
      } w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}
    >
      {/* <div className="basis-full grid grid-cols-12 gap-2">
        <div className="col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
        </div>
        <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
          {showSuggestedPrices &&
            suggestedPrices.map((item, index) => {
              return (
                <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                  <div
                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${
                      selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                        ? 'bg-[#F60362] text-white'
                        : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                    }`}
                    onClick={() => {
                      handleSelectedItem(currentCurrency, index);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
          style={styles.shadow}
        >
          <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
          <input
            value={productAmount}
            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
            type="number"
            onChange={e => {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }}
            placeholder="Enter amount"
          />
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-center items-center-">
          <button
            disabled={!selectedProduct.id}
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            {donateBtnText ? donateBtnText : lang === 'fr' ? 'Faire un don rapide' : arabic || lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default CrisisWidget;
