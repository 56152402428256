import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { BASKET_CACHE_KEY, HandleLocalStorage, IsExpired } from './handleLocalStorage';
import HandleShowAdminCost from './handleAdminCost';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../store/user';
import { useReducer } from 'react';
import IsGTMScriptLoaded from './isGTMScriptLoaded';

const useHandleAddProduct = () => {
  const navigate = useNavigate();
  // const pixelID = localStorage.getItem('TikTokID')
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const initialState = {
    timestamp: Date.now() + 24 * 60 * 60 * 7 * 1000,
    stripe_customer_id: '',
    currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
    mode: 'payment',
    adminCost: 0,
    grandTotal: 0,
    total: 0,
    recurring: 'one-off',
    paymentFlow: '',
    donor: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postal_code: '',
      countryCode: '',
      country: '',
    },
    user: {
      currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
      email: '',
      id: '',
      donor_id: '',
      name: '',
      phone: '',
      plaque_name: '',
      address: '',
      postal_code: '',
      city: '',
    },
    products: [
      {
        product: null,
        quantity: 1,
        amount: 0,
        total: 0,
        currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
        recurring: 'one-off',
        landing_page: '',
        onBehalf: 'For the sake of Allah SWT',
      },
    ],
  };
  const [basketStates, setBasketStates] = useReducer((basketStates, setBasketStates) => ({ ...basketStates, ...setBasketStates }), initialState);
  // useEffect(() => {
  //   if (basketStatesFromLocalStorage) {
  //     setBasketStates(basketStatesFromLocalStorage);
  //   }
  // }, [basketStatesFromLocalStorage]);
  const editProduct = ({ product, currency, openOnNewTab = false, justAddToLocalStorage = false }) => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempBasket = basketStates;
    if (basketStatesFromLocalStorage && !IsExpired(basketStatesFromLocalStorage)) {
      tempBasket = basketStatesFromLocalStorage;
      setBasketStates(basketStatesFromLocalStorage);
    }
    let product_recurring = '';
    if (product && product.recurring === 'Allowed') {
      tempBasket.recurring ? (product_recurring = tempBasket.recurring) : (product_recurring = 'one-off');
    } else {
      tempBasket.recurring !== product.recurring ? (product_recurring = product.recurring) : (product_recurring = product.recurring);
    }
    let product_mode = product_recurring === 'one-off' ? 'payment' : 'subscription';
    let product_quantity =
      product['aud'] < 2 ? (product.quantity ? (product.quantity === 1 ? 1 : product.quantity) : 100) : product.quantity ? product.quantity : 1;
    let exist_prd_index = 0;
    let newProducts = tempBasket.products.map((item, index) => {
      if (item.product && item.product.creator === product.creator) {
        let _q = item.quantity + product_quantity;
        let _t = _q * item.product[currency];
        exist_prd_index = index;
        item.currency = currency;
        item.product.currency = currency;
        item.quantity = _q;
        item.total = _t;
        item.amount = item.product[currency];
      }
      return item;
    });
    // console.log("on newProducts=",newProducts)
    tempBasket.products = newProducts;
    // tempBasket.products[exist_prd_index].total = product_quantity * tempBasket.products[exist_prd_index].product[currency];
    let tempGrandTotal = tempBasket.adminCost;
    let tempTotal = 0;
    let temp_landing_page = window.location.pathname === '/' ? '/home' : window.location.pathname;
    tempBasket.products
      .map(item => {
        // console.log("on total=",tempGrandTotal,item.total)
        item.recurring = product_recurring;
        item.landing_page = temp_landing_page;
        // item.product ? (tempGrandTotal += item.total) : (tempGrandTotal = tempBasket.adminCost);
        if (item.product) {
          tempGrandTotal += item.total
          tempTotal += item.total
        } else {
          tempGrandTotal = tempBasket.adminCost
        }
        return item;
      })
      .filter(item => item.total > 0);
    tempBasket.grandTotal = tempGrandTotal;
    tempBasket.total = tempTotal;
    tempBasket.mode = product_mode;
    if (tempBasket.currency !== currency) {
      let newTempGrandTotal = 0;
      tempBasket.products.map(item => {
        if (item.product) {
          item.currency = currency;
          item.product.currency = currency;
          item.total = item.quantity * item.product[currency];
          item.amount = item.product[currency];
          newTempGrandTotal += item.quantity * item.product[currency];
        }
        return item;
      });
      let newTempTotal = newTempGrandTotal;
      newTempGrandTotal = tempBasket.adminCost ? Number(newTempGrandTotal) + Number(HandleShowAdminCost(currency)) : Number(newTempGrandTotal);
      tempBasket.adminCost = tempBasket.adminCost ? Number(HandleShowAdminCost(currency)) : 0;
      tempBasket.grandTotal = newTempGrandTotal;
      tempBasket.total = newTempTotal;
    }
    tempBasket.recurring = product_recurring;
    tempBasket.currency = currency;
    if (!justAddToLocalStorage) {
      openOnNewTab ? window.open(`/checkout`, '_blank') : navigate(`/checkout`);
    }
    HandleLocalStorage(tempBasket);
    handleAddToCartDataLayer(tempBasket.products[exist_prd_index], tempBasket.currency);
  };
  const removeProduct = product => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempBasket = basketStates;
    if (basketStatesFromLocalStorage && !IsExpired(basketStatesFromLocalStorage)) {
      tempBasket = basketStatesFromLocalStorage;
      setBasketStates(basketStatesFromLocalStorage);
    }
    let newUserProducts = tempBasket.products.map(obj => obj).filter(item => item.product.creator !== product.creator);
    if (!newUserProducts.length) {
      newUserProducts = initialState.products;
      tempBasket.adminCost = 0;
      tempBasket.grandTotal = 0;
      tempBasket.total = 0;
    }
    tempBasket.products = newUserProducts;
    let tempGrandTotal = tempBasket.adminCost;
    let tempTotal = 0;
    tempBasket.products
      .map(item => {
        item.product ? (tempGrandTotal += item.total) : (tempGrandTotal = tempBasket.adminCost);
        item.product ? (tempTotal += item.total) : (tempTotal = 0);
        return item;
      })
      .filter(item => item.total > 0);
    tempBasket.grandTotal = tempGrandTotal;
    tempBasket.total = tempTotal;
    HandleLocalStorage(tempBasket);
  };
  const addProduct = ({
    product,
    currency,
    type = 'single',
    openOnNewTab = false,
    justAddToLocalStorage = false,
    _basketStates = basketStates,
    defaultPrice = 100,
  }) => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempBasket = _basketStates;
    if (basketStatesFromLocalStorage && !IsExpired(basketStatesFromLocalStorage)) {
      tempBasket = basketStatesFromLocalStorage;
      setBasketStates(basketStatesFromLocalStorage);
    }
    const newUserProducts = tempBasket.products.map(obj => obj).filter(item => item.total > 0);
    tempBasket.products = newUserProducts;
    let temp_landing_page = window.location.pathname === '/' ? '/home' : window.location.pathname;
    let isExist = false;
    if (type === 'single') {
      tempBasket.products.map((item, index) => {
        if (item.product && item.product.creator === product.creator) {
          isExist = true;
        }
        return item;
      });
      let product_recurring = '';
      if (product.recurring === 'Allowed') {
        tempBasket.recurring ? (product_recurring = tempBasket.recurring) : (product_recurring = 'one-off');
      } else {
        tempBasket.recurring !== product.recurring ? (product_recurring = product.recurring) : (product_recurring = product.recurring);
      }
      let product_mode = product_recurring === 'one-off' ? 'payment' : 'subscription';
      let product_quantity =
        product['aud'] < 2
          ? product.quantity
            ? product.quantity === 1
              ? 1
              : product.quantity
            : defaultPrice
          : product.quantity
          ? product.quantity
          : 1;
      if (isExist) {
        editProduct({ product: product, currency: currency, openOnNewTab, justAddToLocalStorage });
      } else {
        let tempProduct = {
          product: {
            AUD: product.aud,
            CAD: product.cad,
            EUR: product.eur,
            GBP: product.gbp,
            MYR: product.myr,
            SGD: product.sgd,
            USD: product.usd,
            AED: product.aed,
            IDR: product.idr,
            category: product.category,
            category_id: product.category_id,
            creator: product.creator,
            currency: currency,
            custom_amount: product.custom_amount,
            description: product.description,
            image_link: product.image_link,
            name: product.name,
            paypal_plans: product.paypal_plans,
            paypal_subscription_plans: product.paypal_subscription_plans,
            recurring: product_recurring,
            status: product.status,
            unit_amount: product[currency.toLowerCase()],
          },
          quantity: product_quantity,
          amount: product[currency.toLowerCase()],
          total: Number(product_quantity) * Number(product[currency.toLowerCase()]),
          currency: currency,
          recurring: product_recurring,
          landing_page: temp_landing_page,
          onBehalf: 'For the sake of Allah SWT',
        };
        // For laylatul qadr  only
        if (product.start_date) {
          tempProduct = Object.assign({}, tempProduct, {
            start_date: product.start_date,
            end_date: product.end_date,
            continue_donation: product.continue_donation,
            grandTotal: tempProduct.total,
            total: tempProduct.total,
            currency: 'USD',
          });
          tempBasket.products = [tempProduct.product]; //reset to one product only
        }
        tempBasket = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || basketStates;
        // console.log("on handle==",basketStatesFromLocalStorage1)
        if (tempBasket.grandTotal === 0 || !tempBasket.products[0].product) {
          tempBasket.grandTotal = Number(tempProduct.total);
          tempBasket.total = Number(tempProduct.total);
          tempBasket.products = [tempProduct];
          tempBasket.currency = currency;
          tempBasket.recurring = product_recurring;
          tempBasket.mode = product_mode;
          HandleLocalStorage(tempBasket);
          handleAddToCartDataLayer(tempProduct, tempBasket.currency);
          let jsonObj = {
            product: product && product.creator,
            currency: currency,
            id: product && product.id,
            quantity: product_quantity,
            category_id: product && product.category_id,
            landing_page: temp_landing_page,
            recurring: product_recurring,
          };
          if (product.start_date) {
            jsonObj.isLaylatuQadr = true;
          }
          if (!justAddToLocalStorage) {
            openOnNewTab
              ? window.open(
                  `/checkout?id=${Base64.encode(
                    JSON.stringify({
                      ...jsonObj,
                    }),
                  )}`,
                  '_blank',
                )
              : navigate(
                  `/checkout?id=${Base64.encode(
                    JSON.stringify({
                      ...jsonObj,
                    }),
                  )}`,
                );
          }
        } else {
          tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempProduct.total);
          tempBasket.total = tempBasket.adminCost ? Number(tempBasket.grandTotal) - Number(tempBasket.adminCost) + Number(tempProduct.total) : Number(tempBasket.grandTotal) + Number(tempProduct.total);
          tempBasket.products.push(tempProduct);
          tempBasket.mode = product_mode;
          if (tempBasket.currency !== currency) {
            let newTempGrandTotal = 0;
            tempBasket.products.map(item => {
              if (item.product) {
                item.currency = currency;
                item.product.currency = currency;
                item.total = item.quantity * item.product[currency];
                item.amount = item.product[currency];
                newTempGrandTotal += item.quantity * item.product[currency];
              }
              return item;
            });
            let newTempTotal = newTempGrandTotal;
            newTempGrandTotal = tempBasket.adminCost ? Number(newTempGrandTotal) + Number(HandleShowAdminCost(currency)) : Number(newTempGrandTotal);
            tempBasket.adminCost = tempBasket.adminCost ? Number(HandleShowAdminCost(currency)) : 0;
            tempBasket.grandTotal = newTempGrandTotal;
            tempBasket.total = newTempTotal;
          }
          tempBasket.products.map(item => {
            item.recurring = product_recurring;
            return item;
          });
          tempBasket.recurring = product_recurring;
          tempBasket.currency = currency;
          HandleLocalStorage(tempBasket);
          handleAddToCartDataLayer(tempProduct, tempBasket.currency);
          if (!justAddToLocalStorage) {
            openOnNewTab
              ? window.open(
                  `/checkout?id=${Base64.encode(
                    JSON.stringify({
                      product: product && product.creator,
                      currency: currency,
                      id: product && product.id,
                      quantity: product_quantity,
                      category_id: product && product.category_id,
                      recurring: product_recurring,
                      landing_page: temp_landing_page,
                    }),
                  )}`,
                  '_blank',
                )
              : navigate(
                  `/checkout?id=${Base64.encode(
                    JSON.stringify({
                      product: product && product.creator,
                      currency: currency,
                      id: product && product.id,
                      quantity: product_quantity,
                      category_id: product && product.category_id,
                      recurring: product_recurring,
                      landing_page: temp_landing_page,
                    }),
                  )}`,
                );
          }
        }
      }
    } else {
      let product_recurring = tempBasket.recurring ? tempBasket.recurring : 'one-off';
      let product_mode = product_recurring === 'one-off' ? 'payment' : 'subscription';
      let tempProducts = [];
      // let tljempGrandTotal = 0;
      product.map(prd => {
        let product_quantity = prd.quantity ? prd.quantity : 1;
        let tempPrd = {
          product: {
            AUD: prd.aud,
            CAD: prd.cad,
            EUR: prd.eur,
            GBP: prd.gbp,
            MYR: prd.myr,
            SGD: prd.sgd,
            USD: prd.usd,
            AED: prd.aed,
            IDR: prd.idr,
            category: prd.category,
            category_id: prd.category_id,
            creator: prd.creator,
            currency: currency,
            custom_amount: prd.custom_amount,
            description: prd.description,
            image_link: prd.image_link,
            name: prd.name,
            paypal_plans: prd.paypal_plans,
            paypal_subscription_plans: prd.paypal_subscription_plans,
            recurring: product_recurring,
            status: prd.status,
            unit_amount: prd[currency.toLowerCase()],
          },
          quantity: product_quantity,
          amount: prd[currency.toLowerCase()],
          total: Number(product_quantity) * Number(prd[currency.toLowerCase()]),
          currency: currency,
          recurring: product_recurring,
          landing_page: temp_landing_page,
          onBehalf: 'For the sake of Allah SWT',
        };
        tempProducts.push(tempPrd);
        // tempGrandTotal += Number(product_quantity) * Number(prd[currency.toLowerCase()]);
        handleAddToCartDataLayer(tempPrd, currency);
        return prd;
      });
      // tempBasket.grandTotal = Number(tempGrandTotal);
      // tempBasket.products = tempProducts;
      // let newProducts = tempBasket.products.concat(tempProducts);
      let newProducts = [];
      if (tempBasket.products[0].product) {
        newProducts = tempBasket.products.concat(tempProducts);
      } else {
        newProducts = tempProducts;
      }
      tempBasket.products = newProducts;
      let tempTotalProducts = 0;
      newProducts.map(item => {
        item.product ? (tempTotalProducts += item.total) : (tempTotalProducts = 0);
        return null;
      });
      tempBasket.grandTotal = Number(tempTotalProducts) + Number(tempBasket.adminCost);
      tempBasket.total = Number(tempTotalProducts);
      tempBasket.currency = currency;
      tempBasket.recurring = product_recurring;
      tempBasket.mode = product_mode;
      // console.log('before navigate=', tempBasket);
      HandleLocalStorage(tempBasket);
      navigate(`/checkout`);
    }
  };
  return [addProduct, editProduct, removeProduct];
};

const handleAddToCartDataLayer = (product, currency) => {
  let dataLayerTemp = {
    event: 'add_to_cart',
    ecommerce: {
      value: product.total,
      currency: currency.toUpperCase(),
      items: [
        {
          item_id: product.product.creator,
          item_name: product.product.name,
          item_brand: product.product.category || '',
          item_category: product.product.category || '',
          item_description: product.product.description || '',
          price: product.amount,
          quantity: product.quantity,
        },
      ],
    },
  };
  window.dataLayer = window.dataLayer || [];
  IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  let obj = {
    content_type: 'product',
    content_id: product.product.creator,
    currency: currency.toUpperCase(),
    // value: tempAmount * tempQuantity,
    value: product.total,
    price: product.amount,
    quantity: product.quantity,
    content_category: product.category ? product.category : 'donation',
    content_name: product.product.name,
    description: product.product.description,
  };
  window.ttq &&
    window.ttq.track('AddToCart', {
      ...obj,
    });
};

export default useHandleAddProduct;