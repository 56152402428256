import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import CustomButton from '../../../components/general/button';
import Sticky from 'react-sticky-el';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import BangladeshTabContent from './bangladesh-tab-content';

const BangladeshTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { foodAidProducts, medicalAidProducts, orphansSupportProducts, whereMostNeededProducts, lang = 'en', arabic = false } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const contentRef = useRef(null);
  const tabsContainerRef = useRef(null);
  const [scrollLeftEnd, setScrollLeftEnd] = useState(true);

  const [activeTabProducts, setActiveTabProducts] = useState(0);

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  const tabButtonsProducts = [
    {
      tabTitle: lang === 'fr' ? 'Là où on en a le plus besoin' : lang === 'ar' ? 'حيث الحاجة الأكثر' : 'Where Most Needed',
    },
    {
      tabTitle: lang === 'fr' ? 'Aide alimentaire' : lang === 'ar' ? 'المساعدات الغذائية' : 'Food Aid',
    },
    {
      tabTitle: lang === 'fr' ? 'Aide médicale' : lang === 'ar' ? 'المساعدات الطبية' : 'Medical Aid',
    },
    {
      tabTitle: lang === 'fr' ? 'Soutien aux orphelins' : lang === 'ar' ? 'دعم الأيتام' : 'Orphans Support',
    },
  ];

  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    setActiveTabProducts(index);

    const tabsContainer = tabsContainerRef.current;
    const targetTab = tabsContainer.children[index];

    // Calculate the scroll offset to center the tab
    const scrollLeft = targetTab.offsetLeft + targetTab.clientWidth / 2 - tabsContainer.clientWidth / 2;
    tabsContainer.scroll({
      left: scrollLeft,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const tabsContainer = tabsContainerRef.current;
    if (tabsContainer) {
      tabsContainer.scrollLeft = 0; // Ensure the scroll is at the start
    }

    const handleScroll = () => {
      const scrollX = tabsContainer.scrollLeft;

      // Calculate the maximum scrollable width
      const maxScrollLeft = tabsContainer.scrollWidth - tabsContainer.clientWidth;

      // Check if scrolled to the rightmost part
      if (scrollX === maxScrollLeft) {
        setScrollLeftEnd(false);
        console.log('Scrolled to right edge.');
      } else if (scrollX === 0) {
        setScrollLeftEnd(true);
        console.log('Scrolled to left edge.');
      } else {
        setScrollLeftEnd(false);
      }
    };

    tabsContainer.addEventListener('scroll', handleScroll);

    return () => {
      tabsContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className="bg-[#fff]" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div
        className={`${activeTabProducts === 1 || activeTabProducts === 3 ? '' : 'md:container'} w-full mx-auto ${
          scrollLeftEnd ? 'pl-2 pr-0' : ''
        } md:px-0 py-4 md:py-12`}
      >
        <div className="hidden sm:flex items-center w-full overflow-x-auto">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {tabButtonsProducts.map(({ tabTitle }, index) => (
              <CustomButton
                key={`btn_${index}`}
                title={tabTitle}
                onClick={handleTabChange.bind(this, index)}
                className={`${
                  index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'
                } mr-2 md:mr-0 min-w-fit !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${
                  lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                }`}
              />
            ))}
          </div>
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10 bg-white overflow-x-auto">
          <Sticky
            stickyStyle={{ top: '64px', bottom: 'unset' }}
            stickyClassName="!w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_15px_25px_-1px] z-10 bg-white left-0"
            mode="top"
            onFixedToggle={() => {
              setIsTabsFixed(!isTabsFixed);
            }}
          >
            <div
              className={`flex gap-1 items-center w-full mx-auto ${isTabsFixed ? 'justify-around' : 'overflow-x-auto min-w-[650px] max-w-[700px]'}`}
              ref={tabsContainerRef}
            >
              {tabButtonsProducts.map(({ tabTitle }, index) => (
                <CustomButton
                  key={`btn_${index}`}
                  title={tabTitle}
                  onClick={handleTabChange.bind(this, index)}
                  className={`${
                    isTabsFixed
                      ? index === activeTabProducts
                        ? '!text-[#F60362] !bg-white'
                        : '!text-[#78716C] !bg-white text-[10px] !px-0 !py-1'
                      : index === activeTabProducts
                      ? '!bg-DarkerBlue text-white !rounded-full p-2 min-w-fit mr-2-'
                      : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue mr-2- min-w-fit !rounded-full p-2'
                  }
                     ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'}`}
                />
              ))}
            </div>
          </Sticky>
        </div>
      </div>
      <div className={`w-full mx-auto px-4 md:px-0 py-4 md:py-12 bg-[#253B7E]`}>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            {activeTabProducts === 0 && (
              <div ref={contentRef}>
                <BangladeshTabContent
                  arabic={arabic}
                  lang={lang}
                  products={whereMostNeededProducts}
                  currency={selectedCurrency}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
              </div>
            )}
            {activeTabProducts === 1 && (
              <div ref={contentRef}>
                <BangladeshTabContent
                  arabic={arabic}
                  lang={lang}
                  products={foodAidProducts}
                  currency={selectedCurrency}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
              </div>
            )}
            {activeTabProducts === 2 && (
              <div ref={contentRef}>
                <BangladeshTabContent
                  arabic={arabic}
                  lang={lang}
                  products={medicalAidProducts}
                  currency={selectedCurrency}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
              </div>
            )}
            {activeTabProducts === 3 && (
              <div ref={contentRef}>
                <BangladeshTabContent
                  arabic={arabic}
                  lang={lang}
                  products={orphansSupportProducts}
                  currency={selectedCurrency}
                  showDots={false}
                  showArrows={false}
                  showMultiProductsOnRow={false}
                  viewAppealsLink={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default BangladeshTabs;