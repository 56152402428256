import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import { useKeenSlider } from 'keen-slider/react'
import { selectProducts } from '../../store/products';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import AppealCarouselSection from './carouselSection';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import Arrow from '../home/components/tab-arrow';
import WidgetHome from '../../components/widgets/widgetHome';
import { useNavigate } from 'react-router-dom';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import AppealTabs from './appealTabs';
import OrphanFeast1 from '../../images/orphans/orphanFeast1.jpg';
import TotalImpactSection from '../crisis-and-emergencies/components/common-sections/totalImpacts';

const AppealLandingPageV2 = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const products = useSelector(state => selectProducts(state));
    const [imageHasError, setImageHasError] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [page, setPage] = useState();
    const [pageProducts, setPageProducts] = useState([0, 0, 0, 0])
    const [sectionsOrder, setSectionsOrder] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: "snap",
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,
        },
    })
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    // const getAllProducts = (_prds) => {
    //     // setIsLoaded(true);
    //     let tempProductsList = [];
    //     products.map(item => {
    //         item.sub_categories.map(inner_item => {
    //             inner_item.products.map(inner_item_product => {
    //                 _prds.map(prd_item => {
    //                     if (prd_item.creator === inner_item_product.creator) tempProductsList.push(inner_item_product)
    //                     return prd_item;
    //                 })
    //                 return inner_item_product;
    //             });
    //             return inner_item;
    //         });
    //         return item;
    //     });
    // };
    const getPages = async () => {
        let pathNames = window.location.pathname.split('/');
        // pathNames.length > 1 && getProduct(pathNames[2].split('-').join(' ').toLowerCase(), selectedCurrencyStore);
        let isExistPage = false;
        setIsLoading(true);
        try {
            const response = await services.getUserAllPages();
            // console.log('🚀~ get-pages ~ response:', response);
            response.data.data.map(item => {
                if (item.page_url.toLowerCase() === pathNames[1].toLowerCase()) {
                    let tempOrders = [];
                    item.product_blocks.map((prd_item, index) => {
                        // tempOrders.push({order:Number(prd_item.order),id:prd_item.id,type: 'product'})
                        index === 0 && tempOrders.push({ order: (2 * index) + 1, id: prd_item.id, type: 'product' })
                    })
                    let contentsOfRow = [];
                    let contentOrder = { order: 0, id: 0 };
                    item.content_blocks[2].order = 3;
                    item.content_blocks.map(cnt_item => {
                        // tempOrders.push({order:Number(cnt_item.order),id:cnt_item.id,type: 'content'})
                        console.log("cnt_item=",cnt_item,contentOrder)
                        if (cnt_item.order === contentOrder.order) {
                            contentsOfRow.map(_item => {
                                if (_item.order === cnt_item.order) _item.otherContentId = cnt_item.id;
                            })
                        } else {
                            contentOrder = { order: cnt_item.order, id: cnt_item.id };
                            contentsOfRow.push({ order: cnt_item.order, id: cnt_item.id, type: 'content', otherContentId: '' })
                        }
                    })
                    contentsOfRow[0].order = 2;
                    tempOrders = tempOrders.concat(contentsOfRow);
                    let testCompare = tempOrders.sort((a, b) => a.order - b.order)
                    console.log("page seledted=", testCompare, item)
                    setSectionsOrder(testCompare);
                    setPage(item);
                    setPageProducts(item.products);
                    isExistPage = true;
                    // getAllProducts(item.products);
                }
                // item.name === pathNames[2] && setPage(item)
                return item
            })
            !isExistPage && navigate('/');
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, `<br/>`) }} />;
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);
    useEffect(() => {
        getPages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='bg-white' hideFooter showRegularImg={false}>
            {page &&
                <Helmet>
                    <title>{page.meta || ''}</title>
                    <meta name="title" content={page.meta || ''} />
                    <meta name="description" content={page.description || ''} />
                </Helmet>
            }
            {page && page.home_widget === 1 && <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />}
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                {page
                    ? page.banners.length > 1
                        ? <AppealCarouselSection banners={page.banners} />
                        : page.banners.length === 0
                            ? <></>
                            : <img className={`${imageHasError ? 'w-auto max-h-[300px]' : 'w-full'}`} src={page.banners[0] ? page.banners[0].image : '/images/general/logo-replace.png'}
                                onError={({ currentTarget }) => {
                                    setImageHasError(true)
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                alt="" />
                    : <></>
                }
            </section>
            {console.log("sections=", sectionsOrder)}
            {sectionsOrder.map((item, index) => {
                return <section className={item.type === 'product' ? 'bg-[#253B7E]-' : ''} key={`sections${index}`}>
                    {/*{item.type === 'product' && <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
                         {page.product_blocks.map(prd => {
                            return prd.id === item.id && <>
                                <div className='hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4'>
                                    {prd.products.map((item, index) => {
                                        return index < 4 && <div key={`pageProducts${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 lg:basis-1/4 max-w-[300px]">
                                            <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                        </div>
                                    })}
                                </div>
                                <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
                                    {prd.products[0] && prd.products.length > 1 && <>
                                        <div className='absolute translate-y-1/2 h-full- h-[28px] top-[47%] -left-[5px] z-10'
                                            onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                                        >
                                            <LiaAngleLeftSolid color='white' size={25} />
                                        </div>
                                        <div className='absolute translate-y-1/2 h-full- h-[28px] top-[47%] -right-[5px] z-10'
                                            onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                                        >
                                            <LiaAngleRightSolid color='white' size={25} />
                                        </div>
                                    </>}
                                    <div ref={sliderRef} className="keen-slider">
                                        {prd.products.map((item, index) => {
                                            return <div key={`pageProducts${index}`} className="keen-slider__slide basis-full flex justify-center">
                                                <div className="w-full max-w-[300px]">
                                                    <ProductBox product={item} currency={selectedCurrencyStore ? selectedCurrencyStore : 'AUD'} />
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    {prd.products[0] && prd.products.length > 1 && <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
                                        {loaded && instanceRef.current && (
                                            <div className="flex justify-center items-center relative w-full">
                                                <Arrow
                                                    left
                                                    onClick={(e) =>
                                                        e.stopPropagation() || instanceRef.current?.prev()
                                                    }
                                                    disabled={currentSlide === 0}
                                                />
                                                {[...Array(prd.products.length).keys()].map(idx => {
                                                    return (
                                                        <button
                                                            key={`q${idx}`}
                                                            onClick={() => {
                                                                instanceRef.current?.moveToIdx(idx);
                                                            }}
                                                            className={
                                                                'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                                                                (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                                                            }
                                                        ></button>
                                                    );
                                                })}
                                                <Arrow
                                                    onClick={(e) =>
                                                        e.stopPropagation() || instanceRef.current?.next()
                                                    }
                                                    disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                                                />
                                            </div>
                                        )}
                                    </div>}
                                </div>
                            </>
                        })}
                    </div>}*/}
                    {item.type === 'product' && <AppealTabs productBlocks={page.product_blocks} />}
                    {item.type === 'content' && <div className="md:container grid grid-cols-12 gap-2 md:gap-4 w-full mx-auto px-4 md:px-0 py-4 md:py-12">
                        {page.content_blocks.map(cnt => {
                            return <>
                                {cnt.id === item.id && <>
                                    <div className={`col-span-12 ${item.otherContentId ? 'md:col-span-6' : ''} ${item.otherContentId && cnt.position === 'left' ? 'order-1' : 'order-2'}`}>
                                        {cnt.type === 'image'
                                            ? <img src={OrphanFeast1} alt="Islamic Donation for emergency appeals" className='rounded-lg' />
                                            : cnt.type === 'video'
                                                ? <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} />
                                                : <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
                                                    <h1 className={`text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 w-full ${item.otherContentId ? 'text-left' : 'text-center'}`}>
                                                        {cnt.title}
                                                    </h1>
                                                    <p className='text-base md:text-lg text-[#78716C]'>
                                                        {cnt.content}
                                                    </p>
                                                </div>
                                        }
                                    </div>
                                </>
                                }
                                {item.otherContentId && cnt.id === item.otherContentId && <div className={`col-span-12 md:col-span-6 ${cnt.position === 'left' ? 'order-1' : 'order-2'}`}>
                                    {cnt.type === 'image'
                                        ? <img src={OrphanFeast1} alt="Islamic Donation for emergency appeals" className='rounded-lg' />
                                        : cnt.type === 'video'
                                            ? <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} />
                                            : <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
                                                <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
                                                    {cnt.title}
                                                </h1>
                                                <p className='text-base md:text-lg text-[#78716C]'>
                                                    {cnt.content}
                                                </p>
                                            </div>
                                    }
                                </div>}
                            </>
                        })}
                    </div>}
                </section>
            })}
            {/* /* -------------------------------------------------------------------------- */}
        </Layout>
    )
}

export default AppealLandingPageV2;