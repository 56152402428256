import React from 'react'
import Layout from '../../components/general/layout'
// import MainPageForm from '../../components/general/mainPageForm'
// import { selectSelectedCurrency } from '../../store/user';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WidgetHome from '../../components/widgets/widgetHome';

const DonorReceipts = () => {
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [selectedCurrency, setSelectedCurrency] = useState('AUD');
  const navigate = useNavigate();
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  // }, [selectedCurrencyStore])
  return (
    <Layout className='bg-white' uk>
      {/* <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} /> */}
      <WidgetHome selectedCurrency={e => console.log(e.value)} />

      <section className="md:container md:mx-auto mt-5 bg-[#00A3DA]">
        <div className="flex py-16 justify-center items-center">
          <div className="w-[320px] sm:w-[600px]">
            <h1 className="text-white text-center text-[40px]"> Get Your Donations Receipts</h1>

            <input className="w-full h-12 rounded pl-2 my-6" placeholder="Enter your email" />

            <button className="rounded w-full bg-[#d82154] h-10 text-white text-xl">Send</button>
          </div>
        </div>
      </section>
      <section className="md:container md:mx-auto mt-5">
        <div className="drop-shadow-md p-4 bg-white">
          <h1 className="text-[32px]">Donations Receipt List</h1>
          <div class="flex flex-col overflow-x-auto">
            <div class="sm:-mx-6 lg:-mx-8">
              <div class=" min-w-full py-2 sm:px-6 lg:px-8">
                <div class="overflow-x-scroll">
                  <table class="min-w-full table-auto text-left text-sm font-light">
                    <thead class="border-b text-[#c4c1c1] ">
                      <tr>
                        <th scope="col" class="px-6 py-4">
                          #
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Name
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Email
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Phone
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Amount
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Rate
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Currency
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Method
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Mode
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Status
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Recurring
                        </th>
                        <th scope="col" class="px-6 py-4">
                          Transaction ID
                        </th>
                        <th scope="col" class="sticky right-0 bg-blue-100 px-6 py-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b text-[#343333]">
                        <td class="whitespace-nowrap px-6 py-4 ">1</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Irshad</td>
                        <td class="whitespace-nowrap px-6 py-4 ">irs*******9@gmail.com</td>
                        <td class="whitespace-nowrap px-6 py-4 ">006143*******74</td>
                        <td class="whitespace-nowrap px-6 py-4 ">10.00</td>
                        <td class="whitespace-nowrap px-6 py-4 ">10.00</td>
                        <td class="whitespace-nowrap px-6 py-4 ">AUD</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Stripe</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Subscription</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Payment</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Paid</td>

                        <td class="whitespace-nowrap px-6 py-4 ">pi_3OGxnmD75ny8E7Ui0oRLd4ru</td>
                        <td class="whitespace-nowrap px-6 py-4 bg-blue-100 sticky right-0">
                          <button
                            onClick={() =>
                              navigate(
                                '/receipt?gateway=stripe&currency=aud&session_id=cs_live_a1xctJO91Pghp4JZbbKNz4hisZ0LgqmxxDKHGu3opK2nOp5fi5FAC00g7A',
                              )
                            }
                            className="bg-[#00A3DA] hover:bg-[#d82154] rounded text-white p-2"
                          >
                            View Receipt
                          </button>
                        </td>
                      </tr>
                      <tr class="border-b text-[#343333]">
                        <td class="whitespace-nowrap px-6 py-4 ">2</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Ahmed</td>
                        <td class="whitespace-nowrap px-6 py-4 ">irs*******9@gmail.com</td>
                        <td class="whitespace-nowrap px-6 py-4 ">006143*******74</td>
                        <td class="whitespace-nowrap px-6 py-4 ">10.00</td>
                        <td class="whitespace-nowrap px-6 py-4 ">10.00</td>
                        <td class="whitespace-nowrap px-6 py-4 ">AUD</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Stripe</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Subscription</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Payment</td>
                        <td class="whitespace-nowrap px-6 py-4 ">Paid</td>

                        <td class="whitespace-nowrap px-6 py-4 ">pi_3OGxnmD75ny8E7Ui0oRLd4ru</td>
                        <td class="whitespace-nowrap px-6 py-4 bg-blue-100 sticky right-0">
                          <button
                            onClick={() =>
                              navigate(
                                '/receipt?gateway=stripe&currency=aud&session_id=cs_live_a1xctJO91Pghp4JZbbKNz4hisZ0LgqmxxDKHGu3opK2nOp5fi5FAC00g7A',
                              )
                            }
                            className="bg-[#00A3DA] hover:bg-[#d82154] rounded text-white p-2"
                          >
                            View Receipt
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DonorReceipts;
